import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { BrokerProfile } from '../models/Broker';
import { getRequest } from '../utils/http';

export interface BrokersListState {
    profiles: BrokerProfile[];
    isLoading: boolean;
}


export interface RequestBrokerProfiles { type: 'REQUEST_BROKER_PROFILES'; }
export interface RecieveBrokerProfiles { type: 'RECIEVE_BROKER_PROFILES'; profiles: BrokerProfile[]; }

export type KnownAction = RequestBrokerProfiles | RecieveBrokerProfiles;


export const actionCreators = {
    getBrokerProfiles: (): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({ type: 'REQUEST_BROKER_PROFILES' });
        getRequest<BrokerProfile[]>(`BrokerProfiles/Get`)
            .then(content =>  dispatch({ type: 'RECIEVE_BROKER_PROFILES', profiles: content }));

        // (async () => {
        //     const response = await fetch(`BrokerProfiles/Get`, {
        //         method: 'GET',
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json'
        //         }
        //     });

        //     const content = await response.json() as BrokerProfile[];
        //     dispatch({ type: 'RECIEVE_BROKER_PROFILES', profiles: content })
        // })();
    }


};
const unloadedState = { profiles: [], isLoading: true };
export const reducer: Reducer<BrokersListState> = (state: BrokersListState | undefined, incomingAction: Action): BrokersListState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_BROKER_PROFILES':
            return {
                isLoading: true,
                profiles: []
            }
        case 'RECIEVE_BROKER_PROFILES':
            return {
                isLoading: false,
                profiles: action.profiles
            }
        default:
            return state;
    }

};