import * as React from 'react';
import { Row, Col, Label, Input, Button, Form, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar} from '@fortawesome/free-solid-svg-icons';
import { BrokerProfile } from '../models/Broker';
import {CountryDropdown} from 'react-country-region-selector';
import GoogleMapContainer from '../components/GoogleMapContainer';
import OverallRatingView from '../components/OverallRatingView';
import { Marker } from 'react-google-maps';
import GenericModalContainer, { ModalType } from '../components/GenericModalContainer';
import { privacyPolicyInfo, termsOfServiceInfo, validationErrorFirstName, validationErrorEmail, validationErrorCountry, validationErrorAgreeTerms, validationErrorComments } from '../utils/Strings2';
import {ErrorMessage, ErrorType } from '../models/Error';
import {InputGroup, InnerInputGroup, CountryDropdownGroup} from '../components/InputGroup';
import TermsOfServiceInfo from '../utils/TermsOfServiceInfo';
import PrivacyPolicyInfo from '../utils/PrivacyPolicyInfo';
import ReviewContainer from '../components/ReviewContainer';


export interface BrokerProfileViewProps {
    isLoading: boolean;
    profile: BrokerProfile;
    uri: string;
    country: string;
    errorMsgs: ErrorMessage[];
    submitOk: boolean;
    updateCountry(val: string, e: React.ChangeEvent<Element> | undefined): any;
    updateState(e: React.FormEvent<HTMLInputElement>): any;
    onSubmit(e: React.MouseEvent<HTMLButtonElement>): any;
}

const BrokerProfileView = (props: BrokerProfileViewProps) => {

    const [privacyPolicyModal, setPrivacyPolicyModal] = React.useState(false);
    const togglePrivacyPolicy = () => setPrivacyPolicyModal(!privacyPolicyModal);

    const [termsOfServiceModal, setTermsOfServiceModal] = React.useState(false);
    const toggleTermsOfService = () => setTermsOfServiceModal(!termsOfServiceModal);


    return (
        <div className="container page-content">
            <Row>
                
                <Col className="broker-profile-info" md={12} lg={8} >
                    <Row>
                        <img className="broker-cover-img" src={`https://static.japanglobalauctions.com/profiles/${props.uri}/cover.jpg`} alt="cover" />
                    </Row>
                    <br />
                    <Row>
                        <Col md={4} lg={3}>
                            <img className="broker-profile-img-full" src={`https://static.japanglobalauctions.com/profiles/${props.uri}/profile.jpg`} alt="profile"/>
                        </Col>
                        <Col md={8} lg={9}>
                            <h1>{props.profile.name}</h1>
                            <h4>{props.profile.city}, {props.profile.countryCode}</h4>
                            <a href={props.profile.website}>{props.profile.website}</a>
                            <br />
                            <a href={`https://www.instagram.com/${props.profile.social}`}>@{props.profile.social}</a>
                            <br />
                            {/* <OverallRatingView rating={4.1} showAllReviewsLink={true} /> */}
                            {/* <ReviewContainer brokerId={props.profile.id} /> */}
                            {/*
                            <Row>
                                <Col>
                                    <FontAwesomeIcon icon={faStar} color="#ffc107" />
                                    <FontAwesomeIcon icon={faStar} color="#ffc107" />
                                    <FontAwesomeIcon icon={faStar} color="#ffc107" />
                                    <FontAwesomeIcon icon={faStar} color="#ffc107" />
                                    <FontAwesomeIcon icon={faStar} color="#ccc" />
                                    <span className="rating-number">4.1</span><a href="#">see all reviews</a>
                                </Col>
                            </Row>
                            <Row>
                            <Col>
                                <Button>Save as my broker</Button>
                            </Col>
                            </Row> */}
                        </Col>
                    </Row>

                    <Row className="broker-bio-section">
                        <Col>
                            <p style={{whiteSpace: 'pre-line'}}>{props.profile.bio}</p>
                        </Col>
                        <Col className="google-map-broker-profile">
                            <GoogleMapContainer
                            longitude={props.profile.longitude}
                            latitude={props.profile.latitude}
                            zoomLevel={8}>
                            <Marker position={{ lat: props.profile.latitude, lng: props.profile.longitude }} />
                            </GoogleMapContainer>
                        </Col>
                    </Row>
                </Col>
                
                <Col id="contact-broker" md={12} lg={{ size: 3, offset: 1 }}>
                    {!props.submitOk ?
                    <div>
                            <h4>Contact {props.profile.name}</h4>
                        <Form>
                            <InputGroup for="firstName"
                                        name="First name" type="text" onChange={props.updateState} id="firstName"
                                        errorMsg={validationErrorFirstName} errorMsgs={props.errorMsgs} errorType={ErrorType.FirstName}  />


                            <InputGroup for="lastName"
                                        name="Last name" id="lastName" type="text" onChange={props.updateState}
                                        errorMsg={validationErrorFirstName} errorMsgs={props.errorMsgs} errorType={ErrorType.LastName} />

                            <InputGroup for="email"
                                        name="Email" id="email" type="text" placeholder="email@example.com" onChange={props.updateState}
                                        errorMsg={validationErrorEmail} errorMsgs={props.errorMsgs} errorType={ErrorType.Email} />
                            <CountryDropdownGroup for="country" name="Country"
                                        value={props.country} id="country" 
                                        onCountryChange={props.updateCountry} errorMsgs={props.errorMsgs}
                                        errorMsg={validationErrorCountry} errorType={ErrorType.Country} />
                            <InputGroup for="phone" errorMsgs={props.errorMsgs}
                                        name="Phone" id="phone" placeholder="604 555 1234" onChange={props.updateState} errorType={ErrorType.Phone}
                                        errorMsg={validationErrorEmail} />
                            <InputGroup for="message" type="textarea" onChange={props.updateState} errorMsgs={props.errorMsgs} errorType={ErrorType.Comment}
                                        name="Comments" id="comments" placeholder="Write your message here..." errorMsg={validationErrorComments}   />
                                        
                            <InnerInputGroup for="agreeTerms" type="checkbox" errorMsgs={props.errorMsgs}
                                        name="" id="agreeTerms" onChange={props.updateState} errorMsg={validationErrorAgreeTerms} errorType={ErrorType.Terms}>
                                
                                    I agree to the <a href="#"  onClick={(e: any) => {e.preventDefault(); togglePrivacyPolicy();}}>privacy policy </a> 
                                               and <a href="#" onClick={(e: any) => {e.preventDefault(); toggleTermsOfService();}}> terms </a>

                                    <GenericModalContainer title="Privacy Policy" 
                                                            
                                                            type={ModalType.Ok}
                                                            isOpen={privacyPolicyModal} 
                                                            toggle={togglePrivacyPolicy}>
                                                            <PrivacyPolicyInfo />
                                    </GenericModalContainer>
                                    <GenericModalContainer title="Terms of Service" 
                                                           type={ModalType.Ok}
                                                           isOpen={termsOfServiceModal} 
                                                           toggle={toggleTermsOfService}>
                                        <TermsOfServiceInfo />
                                    </GenericModalContainer>

                            </InnerInputGroup>
                            <Button onClick={props.onSubmit} type="submit" block>Submit</Button>
                        </Form>
                    </div>
                    :
                    <div>
                        <h2>Thanks for your interest</h2>
                        <p>{props.profile.name} will get back to you shortly.</p>
                        <a href="/">Back to home</a>
                    </div>
                    }
                </Col>
            </Row>
        </div>
    )
};

export default BrokerProfileView;