import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Alert } from '../models/Alert';
import { MakeRegion, ModelEntry, ColorEntry, LocationEntry } from '../models/Listing';
import { postRequest } from '../utils/http';
import { toast } from 'react-toastify';

export interface AlertsState {
    isLoading: boolean;
    alerts: Alert[];
    makes: MakeRegion;
    models: ModelEntry[];
    colors: ColorEntry[];
    locations: LocationEntry[];
}

export interface RequestAlerts{type: 'REQUEST_ALERTS'; }
export interface RecieveAlerts{type: 'RECIEVE_ALERTS'; alerts: Alert[] }
export interface RemoveAlert{type: 'REMOVE_ALERT', alert: Alert };
export interface UpdateAlert {type: 'UPDATE_ALERT', alert: Alert, index: number};
export interface UpdateAlertMakes {type: 'UPDATE_ALERT_MAKES', alert: Alert, index: number};
export interface RecieveFilterData {type: 'RECIEVE_FILTER_DATA', name: string; value: Promise<any> };

export type KnownAction = RequestAlerts | RecieveAlerts | RemoveAlert | UpdateAlert | RecieveFilterData | UpdateAlertMakes;

export const actionCreators = {
    getAlerts:  (userId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'REQUEST_ALERTS' });

        postRequest<Alert[]>(`alert/GetAlerts`, userId)
        .then(alerts => dispatch({type: 'RECIEVE_ALERTS', alerts: alerts}));
    },
    removeAlert: (alert: Alert): AppThunkAction<KnownAction> => (dispatch) => {


        dispatch({type: 'REMOVE_ALERT', alert: alert});

        postRequest<boolean>(`alert/RemoveAlert`, alert)
        .then(content => { if(content){ toast.dark(`✅ Removed alert ${alert.name}`, {toastId: alert.name}); } else toast.error(`❌ Failed to remove alert ${alert.name}`)})
        .catch(onRejected => { toast.error(`❌ Failed to remove alert ${alert.name}`)});
    },
    updateAlert: (alert: Alert, index: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_ALERT', alert: alert, index: index});
    },

    updateAlertMakes: (alert: Alert, index: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_ALERT_MAKES', alert: alert, index: index });
    },

    updateAlertToServer: (alert: Alert): AppThunkAction<KnownAction> => (dispatch) => {

        postRequest<boolean>(`alert/UpdateAlert`, alert)
        .then(content => { if(content){ toast.dark(`✅ Updated alert ${alert.name}`, {toastId: alert.name}); } else toast.error(`❌ Failed to create alert ${alert.name}`)})
        .catch(onRejected => { toast.error(`❌ Failed to update alert ${alert.name}`)});
    },

    requestFilterData: (url: string, name: string): AppThunkAction<KnownAction> => (dispatch) => {

        postRequest<any>(url, null)
        .then(content => dispatch({ type: 'RECIEVE_FILTER_DATA', name: name, value: content}));
    },


};
const unloadedState = { alerts: [], isLoading: false, makes: {}, models: [], colors: [], locations: []};
export const reducer: Reducer<AlertsState> = (state: AlertsState | undefined, incomingAction: Action): AlertsState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ALERTS':
            return {
                ...state,
                isLoading: true,
                alerts: []
            }
        case 'RECIEVE_ALERTS':
            return {
                ...state,
                isLoading: false,
                alerts: action.alerts
            }
        case 'REMOVE_ALERT':
            
            var alerts = state.alerts.filter(a =>  action.alert.id !== a.id);
            
            return {
                ...state,
                alerts: alerts
            }
        case 'UPDATE_ALERT':

            
              var alrts: Alert[] = [];

                for(var i = 0; i < state.alerts.length; i++)
                {
                    if(i !== action.index){
                        alrts.push(state.alerts[i]);
                    }
                    else{
                        alrts.push(action.alert);
                    }

                }

            return {
                ...state,
                alerts: alrts
            }
        case 'UPDATE_ALERT_MAKES':
                return {
                    ...state,
                    alerts: []
                }
            
        case 'RECIEVE_FILTER_DATA':
            return {
                ...state,
                [action.name]: action.value
            }
        default:
            return state;
    }

};