import * as React from 'react';

interface GearInfoProps  {
    gearType: number;
    numberOfGears: number;
}

interface GearInfoState {
    gearType: number;
    numberOfGears: number;
}

class GearInfoConverter extends React.Component<GearInfoProps, GearInfoState> {

    constructor(props: GearInfoProps) {
        super(props);
        this.state = {
            gearType: 0,
            numberOfGears: 0
        }
    }

    public render() {
        if(this.props.gearType === 2 && this.props.numberOfGears > 0) return <span>{this.props.numberOfGears}-Speed </span>
        else if(this.props.gearType === 2) return <span>Manual</span>
        else if(this.props.gearType === 3) return <span>Sequential</span>
        else return <span>Automatic</span>
    }
};

export default GearInfoConverter;