import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { CurrentListingResults, Listing } from '../models/Listing';
import { Review } from '../models/Review';
import {postRequest} from '../utils/http';
import * as ReviewStore  from '../store/ReviewStore';


export interface RequestReviews {type: 'REQUEST_REVIEWS'; brokerId: number; }
export interface RecieveReviews {type: 'RECIEVE_REVIEWS'; reviews: Review[];}
export interface RequestSubmitReview {type: 'REQUEST_SUBMIT_REVIEW' }
export interface RecieveSubmitReview {type: 'RECIEVE_SUBMIT_REVIEW', success: boolean }
export interface UpdateSelectedRating{type: 'UPDATE_SELECTED_RATING', rating: number;}
export interface UpdateComment {type: 'UPDATE_COMMENT', comment: string }

export type KnownAction = RequestReviews | RecieveReviews | RequestSubmitReview | RecieveSubmitReview | UpdateSelectedRating | UpdateComment;

export interface ReviewState {
    reviews: Review[];
    isLoading: boolean;
    selectedRating: number;
    comment: string;
}

export const actionCreators = {
    getReviews: (brokerId: number): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({type: 'REQUEST_REVIEWS', brokerId: brokerId });

        postRequest<Review[]>(`Review/GetReviews`, brokerId)
        .then(reviews => dispatch({type: 'RECIEVE_REVIEWS', reviews: reviews}));

    },

    submitReview: (selectedRating: number, brokerId: number, comment: string, userId: string, firstName: string) : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'REQUEST_SUBMIT_REVIEW', });

        const review: Review = { brokerId: brokerId, rating: selectedRating, comment: comment, userId: userId, reviewerFirstName: firstName, time: new Date() };

        postRequest<boolean>(`Review/PostReview`, review)
        .then(success => { if(success) { 
            dispatch({type: 'REQUEST_REVIEWS', brokerId: brokerId });

            postRequest<Review[]>(`Review/GetReviews`, brokerId)
            .then(reviews => dispatch({type: 'RECIEVE_REVIEWS', reviews: reviews})); }})
    },

    onCommentChange: (comment: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: "UPDATE_COMMENT", comment: comment});
    },

    onRatingChange: (newRating: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: "UPDATE_SELECTED_RATING", rating: newRating});
    }

};
const unloadedState = { reviews: [], isLoading: false, selectedRating: 0, comment: ""};
export const reducer: Reducer<ReviewState> = (state: ReviewState | undefined, incomingAction: Action): ReviewState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_REVIEWS':
            return {
                isLoading: true,
                reviews: [],
                comment: "",
                selectedRating: 0
            }
        case 'RECIEVE_REVIEWS':
            return {
                ...state,
                isLoading: false,
                reviews: action.reviews
            }
        case 'REQUEST_SUBMIT_REVIEW':
            return {
                ...state
            }
        case 'UPDATE_SELECTED_RATING':
            return {
                ...state,
                selectedRating: action.rating
            }
        case 'UPDATE_COMMENT':
            return {
                ...state,
                comment: action.comment
            }
        default:
            return state;
    }

};