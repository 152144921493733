import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { CurrentListingResults, Listing, ListingPageResults } from '../models/Listing';
import * as _ from 'lodash';
import BrokerRequest, { BrokerProfile } from '../models/Broker';
import {postRequest} from '../utils/http';
import { ErrorMessage,  ErrorType } from '../models/Error';

export interface ListingPageState {
    imageSrc: string[] | undefined;
    listing: Listing;
    relatedVehicles: Listing[];
    isLoading: boolean;
    isLinkCopied: boolean;
    brokerProfiles: BrokerProfile[];
    brokerRequest: BrokerRequest;
    brokenImages: string[] | undefined;
    uid: string;
    selectedBrokerId: number;
    latitude: number;
    longitude: number;
    errorMsg: ErrorMessage[];
    successful: boolean;
}


export interface SetLinkState{type: 'SET_LINK_STATE'; value: boolean; }
export interface GetBrokers{type: 'GET_BROKERS'; brokerProfiles: BrokerProfile[];}
export interface RemoveBrokenImages{type: 'REMOVE_BROKEN', imageSrc: string[]|undefined; brokenImages: string[]|undefined; }
export interface RecieveFavorites {type: 'RECIEVE_FAVORITES'; listingResults: CurrentListingResults;}
export interface RemoveFavorite{type: 'REMOVE_FAVORITE', listingId: number};
export interface RequestListing{type: 'REQUEST_LISTING', uid: string };
export interface RecieveListing{type: 'RECIEVE_LISTING', listing: Listing, imageSrc: string[]; latitude: number; longitude: number; successful: boolean }
export interface UpdateBrokerRequest{type: 'UPDATE_BROKER_REQUEST', brokerRequest: BrokerRequest;}
export interface UpdateSelectedBroker {type: 'UPDATE_SELECTED_BROKER', brokerId: number;}
export interface UpdateErrorMessage {type: 'UPDATE_ERROR_MESSAGE', errorMsg: ErrorMessage[];}
export interface RecieveListingFailed{type: 'FAILED_LISTING'}

export type KnownAction = SetLinkState | GetBrokers | RecieveFavorites | RemoveFavorite | RemoveBrokenImages
    | RequestListing | RecieveListing | UpdateBrokerRequest | UpdateSelectedBroker | UpdateErrorMessage | RecieveListingFailed;


export const actionCreators = {

    getBrokerProfiles: () : AppThunkAction<KnownAction> => (dispatch) => {

        var localStorage = window.localStorage;
        var countryCode = localStorage.getItem("clientCountryCode");
        
        if(countryCode != null)
        {
            postRequest<BrokerProfile[]>(`auction/GetBrokerProfiles`, countryCode)
            .then(c => dispatch({type: 'GET_BROKERS', brokerProfiles:  c }));
        }
    },
    removeBroken: (event: React.SyntheticEvent<HTMLImageElement>): AppThunkAction<KnownAction> => (dispatch, getState) => {

        //magic numbers for image not found
        if(event.currentTarget.naturalWidth === 128 && event.currentTarget.naturalHeight === 96)
        {
            var src = event.currentTarget.src;
            var state = getState().listingPage;
            if(state !== undefined)
            {
                var imageSource  = state.imageSrc;
                if(imageSource !== undefined)
                {
                    var images = imageSource.filter(sc => sc !== src);
                    var brokenImages = state.brokenImages;
                    if(brokenImages !== undefined)
                        brokenImages.push(src);

                    _.delay(() => dispatch({type: 'REMOVE_BROKEN', imageSrc: images, brokenImages: brokenImages}), 16);
                }
            }
        }
    },
    share: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'SET_LINK_STATE', value: true});
    },

    resetLinkState: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'SET_LINK_STATE', value: false});
    },
    updateBrokerRequest: (brokerRequest: BrokerRequest): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_BROKER_REQUEST', brokerRequest: brokerRequest});
    },
    updateSelectedBroker: (brokerId: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_SELECTED_BROKER', brokerId: brokerId});
    },
      
    updateListing: (uid: string) : AppThunkAction<KnownAction> => (dispatch, getState) => {

        document.title = "JGA | Loading..." 
        dispatch({type: 'REQUEST_LISTING', uid: uid});
        postRequest<ListingPageResults>(`auction/PostListing`, uid)
        .then(content => {

            if(content.successful)
            {
                let images = [];
                for(let i = 1; i < 4; i++) {
                    images.push(`auction/${content.listing.listingId}-${i}.jpg`);
                }
                //images.push(`https://p3.aleado.com/pic?system=auto&date=${content.listing.auctionLocationInfo.dateInfo.dateTime}&auct=${content.listing.auctionNumberInfo.auctionNumber}&bid=${content.listing.auctionLocationInfo.lotNumber}&number=0`);

                dispatch({type: 'RECIEVE_LISTING', listing: content.listing, imageSrc: images, latitude: content.latitude, longitude: content.longitude, successful: content.successful});
                
                document.title = `JGA | ${content.listing.yearInfo.year} ${content.listing.makeAndModelInfo.make} ${content.listing.makeAndModelInfo.model}`;
            }
            else{
                document.title = "JGA | 404 Not Found";
                dispatch({type: 'FAILED_LISTING' });
            }
        })
    },

    sendBrokerRequest: (brokerRequest: BrokerRequest): AppThunkAction<KnownAction> => (dispatch) => {

        brokerRequest = Object.assign({}, brokerRequest, {url : window.location.href});
        postRequest<void>(`Email/SendBrokerRequest`, brokerRequest);
    },
    setErrorMessage: (errorMsg: ErrorMessage[]): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_ERROR_MESSAGE', errorMsg: errorMsg});
    }


};
const unloadedState = {latitude: 0.0, longitude: 0.0, selectedBrokerId: 0, uid: "", errorMsg: [], brokenImages: [], imageSrc:[], isLinkCopied: false, relatedVehicles: [], isLoading: true, brokerProfiles: [], listing: {} as Listing, brokerRequest: {name: "", email: "", country: "", comments: "", brokerId: 0, url: ""}, successful: false};
export const reducer: Reducer<ListingPageState> = (state: ListingPageState | undefined, incomingAction: Action): ListingPageState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_LINK_STATE':
            return {
                ...state,
                isLinkCopied: action.value
            }
        case 'GET_BROKERS':
            return {
                ...state,
                brokerProfiles: action.brokerProfiles
            }
        case 'REMOVE_BROKEN':
            return {
                ...state,
                imageSrc: action.imageSrc,
                brokenImages: action.brokenImages
                
            }
        case 'REQUEST_LISTING':
            return {
                ...state,
                uid: action.uid,
                isLoading: true
            }
        case 'RECIEVE_LISTING':
            return {
                ...state,
                isLoading: false,
                listing: action.listing,
                latitude: action.latitude,
                longitude: action.longitude,
                imageSrc: action.imageSrc,
                successful: true
            }
        case 'FAILED_LISTING':
            return {
                ...state,
                isLoading: false,
                successful: false
            }
        case 'UPDATE_BROKER_REQUEST':
            return {
                ...state,
                brokerRequest: action.brokerRequest
            }
        case 'UPDATE_SELECTED_BROKER':
            return {
                ...state,
                selectedBrokerId: action.brokerId
            }
        case 'UPDATE_ERROR_MESSAGE':
            return {
                ...state,
                errorMsg: action.errorMsg
            }
        default:
            return state;
    }

};