import * as React from 'react';
import {Modal, ModalHeader, ModalBody, Row, ModalFooter, Button} from 'reactstrap';
import { GenericModalProps, ModalType } from './GenericModalContainer';

const GenericModalView = (props: GenericModalProps) => {

    const okButtonOverride = !props.okButtonTextOverride ? "Ok" : props.okButtonTextOverride;
    const classNameOverride = !props.classNameOverride ? "modal-dialog" : props.classNameOverride;

    const trySubmit = () => {

        if(props.validateModal !== undefined && !props.validateModal())
        {
            return;
        }

        if(props.onSubmit !== undefined) 
        {
            props.onSubmit();
        } 
        props.toggle();

    };


    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className={classNameOverride}>
                    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
                    <ModalBody>
                    <Row>
                      {props.children}
                    </Row>
                    </ModalBody>
                    {props.type === ModalType.Ok ?
                    <ModalFooter>
                        <Button color="primary" onClick={props.toggle}>{okButtonOverride}</Button>
                    </ModalFooter>
                    :
                    props.type === ModalType.OkCancel ?
                    <ModalFooter>
                        <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                        <Button color="primary" onClick={trySubmit}>{okButtonOverride}</Button>{' '}
                    </ModalFooter>
                    :
                    null
                    }
        </Modal>
    );
}

export default GenericModalView;