import * as React from "react";
import GoogleMapView from "../components/GoogleMapView";
  
export interface GoogleMapProps 
{
    longitude: number;
    latitude: number;
    zoomLevel: number;
}

class GoogleMapContainer extends React.Component<GoogleMapProps>
{
    constructor(props: GoogleMapProps){
        super(props);
    }


    public render()
    {
        return <GoogleMapView 
                    zoomLevel={this.props.zoomLevel}
                    latitude={this.props.latitude} 
                    longitude={this.props.longitude}>
                        {this.props.children}
                </GoogleMapView>
    }
}

export default GoogleMapContainer;