import * as React from 'react';

interface ScoreInfoProps {
    score: number
}

interface ScoreInfoState {
    score: number
}

export class ScoreInfoConverter extends React.Component<ScoreInfoProps, ScoreInfoState> {

    constructor(props: ScoreInfoProps){
        super(props);
    }

    public render() {
        if (this.props.score === 0) {
            return <span>N/A</span>
        }
        else if (this.props.score === 1) {
            return <span>0</span>
        }
        else if (this.props.score === 2) {
            return <span>A</span>
        }
        else if (this.props.score === 3) {
            return <span>1</span>
        }
        else if (this.props.score === 4) {
            return <span>2</span>
        }
        else if (this.props.score === 5) {
            return <span>3</span>
        }
        else if (this.props.score === 6) {
            return <span>3.5</span>
        }
        else if (this.props.score === 7) {
            return <span>4</span>
        }
        else if (this.props.score === 8) {
            return <span>4.5</span>
        }
        else if (this.props.score === 9) {
            return <span>5</span>
        }
        else if (this.props.score === 10) {
            return <span>6</span>
        }
        else if (this.props.score === 11) {
            return <span>7</span>
        }
        else if (this.props.score === 12) {
            return <span>8</span>
        }
        else if (this.props.score === 13) {
            return <span>9</span>
        }
        else if (this.props.score === 14) {
            return <span>S</span>
        }
        else if (this.props.score === 15) {
            return <span>R</span>
        }
        else if (this.props.score === 16) {
            return <span>RA</span>
        }
        else {
            return <span>{this.props.score - 1}</span>
        }
    }
}

export default ScoreInfoConverter;