import * as React from 'react';
import {Col, Row, FormGroup, Button, Label, Form, ListGroup, ListGroupItem, Table} from 'reactstrap';
import { BrokerDashboardState } from '../store/BrokerDashboardStore';
import { ConnectionRequest, BidRequest } from '../models/ConnectionRequest';
import User from '../models/User';
import { UserInfo } from '../models/UserInfo';
import { Link } from 'react-router-dom';

export interface BrokerDashboardViewProps {
    connectionRequests: ConnectionRequest[];
    bidRequests: BidRequest[];
    connections: UserInfo[];
    acceptConnection(e: React.MouseEvent<any, MouseEvent>, connectionRequest: ConnectionRequest): void;
    removeConnection(e: React.MouseEvent<any, MouseEvent>, connectionRequest: ConnectionRequest): void;
}

const BrokerDashboardView = (props: BrokerDashboardViewProps) => {

    return ( 
        <div>
            <Col>
            <Row>
                Connection Requests:
            </Row>
            <Col>
                <div className="grid-container">
                {props.connectionRequests.map((connectionRequest: ConnectionRequest) => 
                    <div className="grid-item">
                        <div className="grid-item-container">
                        <div className="grid-item-content">
                            <h3>{connectionRequest.firstName } {connectionRequest.lastName}</h3>
                            <div className="row">
                                <div className="col-lg-12">
                                    <span>Email: {connectionRequest.email}</span>
                                </div>
                                <div className="col-lg-12">
                                    <span>Phone: {connectionRequest.phone}</span>
                                </div>
                                <div className="col-lg-12">
                                    <span>Country: {connectionRequest.country}</span>
                                </div>
                                <div className="col-lg-12">
                                    <span>Comments: Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                        Nam molestie quis purus vel blandit. Nam id mi sed elit blandit elementum. 
                                        Nullam vehicula aliquam lectus, eget laoreet risus hendrerit at. 
                                        Donec vitae ultrices nibh. Fusce feugiat convallis nisi, nec molestie felis tincidunt at.
                                         Sed egestas justo id pharetra rutrum</span>
                                </div>
                                <br />
                                <Button>Accept</Button>
                                <Button>Reject</Button>

                            </div>
                            </div>
                        </div>
                    </div>
                )}
                </div>
            </Col>
            <Row>
                Bid Requests:
            </Row>
            <ListGroup>
            <Col>
                <div className="grid-container">
                    {props.bidRequests.map((bidRequest: BidRequest) => 
                        <div className="grid-item">
                            <Link to={{ pathname: `/auction/${bidRequest.listingUid}` }} className="listing-link">
                            <div className="grid-item-container">
                                <div className="grid-img-container">
                                    <img className="grid-img" src={`https://p3.aleado.com/pic?system=auto&date=${bidRequest.locationInfo.dateInfo.dateTime}&auct=${bidRequest.auctionNumber}&bid=${bidRequest.lotNumber}&number=1`} alt="photo1" />
                                </div>
                                <div className="grid-item-content">
                                    <h6>{bidRequest.yearInfo.year} {bidRequest.makeAndModelInfo.make}</h6>
                                    <h3>{bidRequest.makeAndModelInfo.model}</h3>

                                    <div className="row">
                                    <div className="col-lg-12">
                                        <span>Requestor: {bidRequest.firstName} {bidRequest.lastName}</span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span>Bid Amount: ¥{bidRequest.bidAmount}</span>
                                    </div>
                                    <div className="col-lg-6">
                                        <span>Lot No.: {bidRequest.lotNumber}</span>
                                    </div>
                                </div>
                                <br />
                                <Button >Mark as Entered</Button>
                                <Button >Remove</Button>
                                </div>
                            </div>
                            </Link>
                        </div>
                    )}
                </div>
            </Col>
            </ListGroup>
            <Row>
                Connections: 
            </Row>
            <ListGroup>
                {props.connections.map((user: UserInfo) => 
                <div>
                    <ListGroupItem>
                        {user.firstName} {user.lastName} <br />
                        
                        {user.email}
                        <Button>Remove</Button>
                    </ListGroupItem>
                </div>
                )}
            </ListGroup>
            </Col>
        </div>
    );
}

export default BrokerDashboardView;