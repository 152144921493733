import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {firebase} from '../firebase/Firebase';
import { routerActions } from 'connected-react-router';
import { postRequest } from '../utils/http';
import {UserInfo} from '../models/UserInfo';


export interface RequestSignup {type: 'REQUEST_SIGNUP'; }
export interface RecieveSignup {type: 'RECIEVE_SIGNUP'; errorMsg: string; }
export interface ErrorPassword {type: 'ERROR_SIGNUP'; errorMsg: string; }
export interface UpdateInput {type: 'UPDATE_INPUT', name: string, value: string};

export type KnownAction = RequestSignup | RecieveSignup | ErrorPassword | UpdateInput;

export interface SignupState {
    email: string;
    password: string;
    confirmPassword: string;
    errorMsg: string;
}
export const actionCreators = {
    onSubmit: (email: string, password: string, confirmPassword: string): AppThunkAction<KnownAction> => (dispatch) => {
        if(password !== confirmPassword) {
            dispatch({type: 'ERROR_SIGNUP', errorMsg: 'password does not match'});
        }

        var user = firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((user: any) =>
        {
            let currentUser = firebase.auth().currentUser;

            //Is there a race condition where GetUser is called before CreateUser? Yes!!!
            if(currentUser)
            {
                let email = currentUser.email;
                let userId = currentUser.uid;

                let userInfo: UserInfo = {} as UserInfo
                userInfo.email = (!email) ? "" : email;
                userInfo.userId = userId;
                postRequest<boolean>(`user/CreateUser`, userInfo)
                .then(res => res);
            }

        })
        .catch((error: firebase.auth.Error) => {
    
                const errorCode = error.code;
                if(errorCode !== ''){
                    dispatch({type: 'ERROR_SIGNUP', errorMsg: error.message});
                }

        });
        if(user){
        }

    },
    updateState: (name: string, value: string):  AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_INPUT', name: name, value: value});
    }
};
const unloadedState = { email: "", password: "", confirmPassword: "", errorMsg: "" };
export const reducer: Reducer<SignupState> = (state: SignupState | undefined, incomingAction: Action): SignupState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SIGNUP':
            return {
                ...state,
                errorMsg: ""
            }
        case 'RECIEVE_SIGNUP':
            return {
                ...state,
                errorMsg: action.errorMsg
            }
        case 'ERROR_SIGNUP':
            return {
                ...state,
                errorMsg: action.errorMsg
            }
        case 'UPDATE_INPUT':
            return {
                ...state,
                [action.name] : action.value
            }
        default:
            return state;
    }

};