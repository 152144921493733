import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Label, Input, Button } from 'reactstrap';
import ReactDOM from 'react-dom';
import { Listing } from '../models/Listing';
import ListingItemContainer from './ListingItemContainer';
import {useHistory} from 'react-router-dom';
import {HomeMetaTags} from '../components/MetaTags';

export interface HomeViewProps {
  listings: Listing[],
  isLoading: boolean
}

const Home = (props: HomeViewProps) => {
  
  const history = useHistory();

  return ( <div className="page-content">
    <HomeMetaTags />
    <div className="jga-hero">
      <div className="hero-content">
        <h1>JDM Auctions</h1>
        <h2>Find, buy, and import your dream car.</h2>
        <p>Start browsing or sign up to save your favorites and get notified of new search results</p>
        <Button outline color="white" size="lg" href="/auctions" onClick={(e) => {history.push("/auctions")}}>View active auctions</Button>
        <Button color="light" size="lg" href="/accounts/signup" onClick={(e) => {history.push("/accounts/signup")}}>Create an Account</Button>
        
      </div>
      <img src="jga-hero.jpg" alt="jga-hero" />
    </div>
    <div className="container">
      <Row className="how-it-works">
      <Col md={4} className="step-container">
        <div className="step-circle">
          1
        </div>
        <h3>Browse inventory</h3>
        <p>Find the JDM cars you've always wanted on the world's most comprehensive Japanese auto auction catalog, updated every day</p>
      </Col>
      <Col md={4} className="step-container">
        <div className="step-circle">
          2
        </div>
        <h3>Bid on cars</h3>
        <p>Our Japanese auction experts will assist you with the bidding process so you can be sure you're always getting a great deal</p>
      </Col>
      <Col md={4} className="step-container">
        <div className="step-circle">
          3
        </div>
        <h3>Bring 'em home</h3>
        <p>We've partnered with licensed auto import brokers to handle the paperwork, all you need to do is make space in your garage</p>
      </Col>
    </Row>
      <Row className="suggested-listings">
      <Row>
        <Col>
        <h3 className="suggested-heading">Recently added auctions</h3>
        </Col>
      </Row>
      <Row>

      {props.isLoading? <div className="loading-spinner"></div> : props.listings.map((listing: Listing) =>
        <Col key={listing.uid} xs={6} lg={3} className="suggested-item">
          <div  className="grid-item">
              <ListingItemContainer userId="" onFavorite={() => {}} listing={listing} favorites={[]} />
          </div>
        </Col>
      )}
      </Row>
    </Row>
    </div>
   </div>
);
}

export default connect()(Home);
