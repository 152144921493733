import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as BrokerProfileStore from '../store/BrokerProfileStore';
import BrokerProfileView from './BrokerProfileView';
import { RouteComponentProps } from 'react-router';
import View404 from '../components/View404';
import { BrokerMetaTags } from '../components/MetaTags';
import { titleLoading } from '../utils/Strings2';

type BrokerProfileContainerProps = BrokerProfileStore.BrokerProfileState &
    typeof BrokerProfileStore.actionCreators & RouteComponentProps<{uri: string}>;

class BrokerProfileContainer extends React.Component<BrokerProfileContainerProps> {


    constructor(props: BrokerProfileContainerProps){
        super(props);

        document.title = titleLoading;
    }

    public componentDidMount()
    {
        this.props.getBrokerProfile(this.props.match.params.uri);
        this.updateCountry = this.updateCountry.bind(this);
        this.updateState = this.updateState.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
   

    public render()
    {
        var View = this.props.isValid ? 
        (this.props.isLoading) 
        ?  <div className="loading-spinner"></div> 
        : 
        <div>
            <BrokerMetaTags broker={this.props.profile} />
        <BrokerProfileView onSubmit={this.onSubmit} 
                           submitOk={this.props.submitOk} 
                           updateCountry={this.updateCountry} 
                           updateState={this.updateState} 
                           errorMsgs={this.props.errorMsgs} 
                           profile={this.props.profile} 
                           country={this.props.country}
                           isLoading={this.props.isLoading} 
                           uri={this.props.match.params.uri} />
                           </div> : <View404 />

        return ( View )
    }

    public updateState(event: React.FormEvent<HTMLInputElement>) {
        const {name, value, checked} = event.currentTarget;
        if(event.currentTarget.type === "checkbox"){
            this.props.updateState(name, checked);
        }
        else {
            this.props.updateState(name, value);
        }

        

    }

    public updateCountry(val: string, e: React.ChangeEvent<Element> | undefined)
    {
        this.props.updateCountry(val);
    }

    public onSubmit(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        this.props.onSubmit();
    }
};

const mapStateToProps = (state: ApplicationState) => state.brokerProfile;

export default connect(
    mapStateToProps,
    BrokerProfileStore.actionCreators
)(BrokerProfileContainer as any);