import * as Listings from './ListingStore';
import * as Home from './HomeStore';
import * as Signup from './SignupStore';
import * as Login from './LoginStore';
import * as Favorites from './FavoritesStore';
import * as Alerts from './AlertsStore';
import * as BrokersList from './BrokersListStore';
import * as BrokerProfile from './BrokerProfileStore';
import * as BrokerSignup from './BrokerSignupStore';
import * as ListingPage from './ListingPageStore';
import * as BrokerDashboard from './BrokerDashboardStore';
import * as Review from './ReviewStore';
import {firebaseReducer} from 'react-redux-firebase';


// The top-level state object
export interface ApplicationState {
    listings: Listings.ListingContainerState | undefined;
    home: Home.HomeState | undefined;
    signup: Signup.SignupState | undefined;
    login: Login.LoginState | undefined;
    favorites: Favorites.FavoritesState | undefined;
    alerts: Alerts.AlertsState | undefined;
    brokersList: BrokersList.BrokersListState | undefined;
    brokerProfile: BrokerProfile.BrokerProfileState | undefined;
    brokerSignup: BrokerSignup.BrokerSignupState | undefined;
    brokerDashboard: BrokerDashboard.BrokerDashboardState | undefined;
    listingPage: ListingPage.ListingPageState | undefined;
    reviews: Review.ReviewState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    listings: Listings.reducer,
    home: Home.reducer,
    signup: Signup.reducer,
    login: Login.reducer,
    favorites: Favorites.reducer,
    alerts: Alerts.reducer,
    firebase: firebaseReducer,
    brokersList: BrokersList.reducer,
    brokerProfile: BrokerProfile.reducer,
    brokerSignup: BrokerSignup.reducer,
    brokerDashboard: BrokerDashboard.reducer,
    listingPage: ListingPage.reducer,
    reviews: Review.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
