import * as React from 'react';
import { Badge, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import {InputGroup, InnerInputGroup, CountryDropdownGroup} from '../components/InputGroup';
import GenericModalContainer, { ModalType } from './GenericModalContainer';
import { privacyPolicyInfo, termsOfServiceInfo, validationErrorFirstName, validationErrorEmail, validationErrorMessage } from '../utils/Strings2';
import { ContactUsRequest } from '../models/Broker';
import { postRequest } from '../utils/http';
import { ContactUsMetaTags } from './MetaTags';
import { ErrorMessage, ErrorType } from '../models/Error';

const ContactUsView = (props: any) => {

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [brokerageName, setBrokerageName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [newFeaturesList, setNewFeaturesList] = React.useState(false);
    const [errorMsgs, setErrorMessage] = React.useState<ErrorMessage[]>([]);
    const [submitOk, setSubmitOk] = React.useState(false);

    const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        let errorMsgs: ErrorMessage[] = [];

        setErrorMessage(errorMsgs);

        if(firstName === "") { errorMsgs.push(new ErrorMessage(ErrorType.FirstName, "Please fill out your first name"));}
        if (lastName === "") { errorMsgs.push(new ErrorMessage(ErrorType.LastName, "Please fill out your last name")); }
        if(email === "") { errorMsgs.push(new ErrorMessage(ErrorType.Email, "Please fill out your email")); }
        if(message === "") {errorMsgs.push(new ErrorMessage(ErrorType.Message, "Please fill out the message field")); }

        if(errorMsgs.length > 0)
            return;
        
        const contactUsRequest: ContactUsRequest = {firstName: firstName, lastName: lastName, email: email, brokerageName: brokerageName, phone: phone, comments: message, mailList: newFeaturesList };
        postRequest<boolean>(`Email/SendContactUsRequest`, contactUsRequest).then(c => c);
        setSubmitOk(true);

    }

    return (
        <div className="container">
            <ContactUsMetaTags />
            <Row>

                <Col md="6" className="broker-valueprops">
                    <h1 className="become-a-preferred-broker">Contact us</h1>
                    <br />
                    <br />
                    <h3>
                        We'd love to hear from you
                    </h3>
                    <p>
                        We're a small, passionate team devoted to creating the best possible experience for buying and importing Japanese-made cars. We're still hard at work adding more great features, so if you have ideas, general inquiries, or if you'd like to work with us, please send us a message using the contact form.
                    </p>
                </Col>
                <Col sm="12" md="6" lg="4" className="offset-lg-2 broker-signup-form">
                    {!submitOk ?
                        <div>
                            <h2>Contact form</h2>
                            <br />
                            <Form>
                                <Row>
                                    <Col md="6">
                                        <InputGroup for="firstName"
                                                    name="First name" type="text" onChange={(e) => setFirstName(e.target.value)} id="firstName"
                                                    errorMsg={validationErrorFirstName} errorMsgs={errorMsgs} errorType={ErrorType.FirstName}  />
                                    </Col>
                                    <Col md="6">
                                    <InputGroup for="lastName"
                                                    name="Last name" id="lastName" type="text" onChange={(e) => setLastName(e.target.value)} 
                                                    errorMsg={validationErrorFirstName} errorMsgs={errorMsgs} errorType={ErrorType.LastName} />
                                    </Col>
                                </Row>
                                <InputGroup for="brokerageName"
                                        name="Brokerage or Importer name (optional)" id="brokerageName" type="text" onChange={props.updateState} /> 

                                <InputGroup for="email"
                                        name="Email" id="email" type="text" placeholder="email@example.com" onChange={(e) => setEmail(e.target.value)}
                                        errorMsg={validationErrorEmail} errorMsgs={errorMsgs} errorType={ErrorType.Email} />

                                <InputGroup for="phone"
                                        name="Phone (optional)" id="phone" placeholder="604 555 1234" onChange={(e) => setPhone(e.target.value)} />

                                <InputGroup for="message" type="textarea"
                                        name="message" id="message" placeholder="Write your message here..." onChange={(e) => setMessage(e.target.value)}
                                        errorMsg={validationErrorMessage} errorMsgs={errorMsgs} errorType={ErrorType.Message} />

                                <InnerInputGroup name="" type="checkbox" id="mailList" onChange={(e) => setNewFeaturesList(e.target.checked)}>
                                    <span>Also add me to the new features mailing list</span>
                                </InnerInputGroup>
                                <Button onClick={onSubmit} type="submit" block>Submit</Button>
                            </Form>
                        </div>
                        :
                        <div>
                            <h2>Thanks for your message</h2>
                            <p>We'll get back to you as soon as we can.</p>
                        </div>
                    }
                </Col>
            </Row>
        </div>
    );

}


export default ContactUsView;