import React from 'react';
import {firebase} from '../firebase/Firebase';
import { Redirect } from 'react-router-dom';
import { reducers } from '../store';
import { useReducer } from 'react';

export default class LogoutContainer extends React.Component<{}, {isLoggedOut: boolean }>{

    constructor(props: any){
        super(props);

        this.state = {
            isLoggedOut: false
        }

        firebase.auth().onAuthStateChanged((user) => {

            var isLoggedOut = !user ? true: false;
            this.setState({isLoggedOut: isLoggedOut});
        });
        
        firebase.auth().signOut();

    }

    public render() {
        return !this.state.isLoggedOut ? <h1>Logging Out..</h1> : <Redirect to='/' />
    }
}