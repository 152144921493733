import React from 'react';
import * as FavoritesStore from '../store/FavoritesStore';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import FavoritesView from '../components/FavoritesView';
import {firebase} from '../firebase/Firebase';
import { Listing } from '../models/Listing';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { FavoritesMetaTags } from './MetaTags';
import { titleFavorites } from '../utils/Strings2';

type FavoritesContainerProps = FavoritesStore.FavoritesState &
typeof FavoritesStore.actionCreators;

class FavoritesContainer extends React.Component<FavoritesContainerProps> {

    constructor(props: FavoritesContainerProps){
        super(props);
        document.title = titleFavorites;
        this.onRemoveFavorite = this.onRemoveFavorite.bind(this);
    }

    public componentDidMount()
    {
        window.scrollTo(0,0);

        firebase.auth().onAuthStateChanged((user) => {
            if(user != null)
            {
                this.props.getFavorites(user.uid);
            }
        });

    }

    public onRemoveFavorite(event: React.MouseEvent<HTMLButtonElement>, favorite: Listing){
        event.preventDefault();
        this.props.removeFavorite(favorite);
    }

    public render() {
        return (
            <div>
                <FavoritesMetaTags />
                <Row className="page-content">

                    <Col lg={4} xl={3}>
                        <h2>Favorites</h2>
                        <p>This page shows all listings you have favorited. If a listing you previously favorited isn't shown here, the auction may have ended.</p>
                    </Col>

                    <Col lg={8} xl={9} id="resultsCol">
                        <FavoritesView isLoading={this.props.isLoading} favorites={this.props.listings} removeFavorite={this.onRemoveFavorite} />
                    </Col>

                </Row>

            </div>
        )
    }

};


const mapStateToProps = (state: ApplicationState) => state.favorites;

export default connect(
    mapStateToProps,
    FavoritesStore.actionCreators
)(FavoritesContainer as any);