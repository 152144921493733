import * as React from 'react';

export interface Notification {
    title: string;
    body: string;
    isOpen: boolean;
}

export interface NotificationContextType  
{
    notifications: Notification[];
    addNotification(notification: Notification): any;
    removeNotification(notification: Notification): any;

};

export const NotificationContext = React.createContext<NotificationContextType>({
    notifications: [],
    addNotification: (notification: Notification) =>  { },
    removeNotification: (notification: Notification) => {},
});