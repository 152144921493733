import * as React from 'react';
import { Link } from 'react-router-dom';
import {firebase} from '../firebase/Firebase';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import './NavMenu.css';
import GenericModalContainer, { ModalType } from './GenericModalContainer';
import LoginContainer from './LoginContainer';
import { NotificationTray } from '../models/NotificationContextProvider';

export class NavMenuContainer extends React.Component<{}, { isOpen: boolean, isAuthenticated: boolean }> {

    public state = {
        isOpen: false,
        isAuthenticated: false
    };

    constructor(props: any){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
    }

    public componentDidMount(){
        firebase.auth().onAuthStateChanged((user) => {
            var isAuthenticated = (!user) ? false: true;
            this.setState({isAuthenticated: isAuthenticated});

        });
    }

    public render() {
        return <NavMenu close={this.close} isOpen={this.state.isOpen} isAuthenticated={this.state.isAuthenticated} toggle={this.toggle} />
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    private close = (e: React.MouseEvent<any, MouseEvent>) => {
        this.setState({isOpen: false});
    }
};

const NavMenu = (props: {isOpen: boolean, isAuthenticated: boolean, toggle(): any, close(e: React.MouseEvent<any, MouseEvent>): void }) => {

        const [loginModal, setLoginModal] = React.useState(false);
        const toggleLogin = () => setLoginModal(!loginModal);

        const closeLogin = () => {
            setLoginModal(false);
        }

        return (

            <header>
                <Navbar className="navbar-expand-lg navbar-toggleable-lg border-bottom box-shadow" light>
                    <div className="container-fluid">
                        <NavbarBrand tag={Link} to="/"><img src="jga-logo.png" alt="logo" /></NavbarBrand>
                        <NavbarToggler onClick={props.toggle} className="mr-2"/>
                        <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={props.isOpen} navbar>
                            {!props.isAuthenticated ?
                                <ul className="navbar-nav flex-grow">
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/auctions" onClick={props.close} >Auctions</NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/brokers" onClick={props.close}>Brokers</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/brokers/signup" onClick={props.close}>Become a Broker</NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <a href="#" className="text-dark nav-link" onClick={(e) => { e.preventDefault(); toggleLogin() }}>Log in</a> 
                                    </NavItem>
                                    <NavItem>
                                            <NavLink tag={Link} className="btn btn-dark" to="/accounts/signup" onClick={props.close}>Sign up</NavLink>
                                    </NavItem>
                                </ul>
                            : 
                                <ul className="navbar-nav flex-grow">
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/auctions" onClick={props.close}>Auctions</NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/brokers" onClick={props.close}>Brokers</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/brokers/signup" onClick={props.close}>Become a Broker</NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/favorites" onClick={props.close}>Favorites</NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/alerts" onClick={props.close}>Alerts</NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/accounts/logout" onClick={props.close}>Sign out</NavLink>
                                    </NavItem>
                                    
                                </ul>
                            }
                            
                        </Collapse>
                    </div>
                </Navbar>
                <GenericModalContainer  isOpen={loginModal}
                                        toggle={toggleLogin}
                                        title="Log in"
                                        type={ModalType.None} >
                    <LoginContainer onLoginSuccessful={closeLogin} />
                </GenericModalContainer>
            </header>
        );
}
