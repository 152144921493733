import React from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as SignupStore from '../store/SignupStore';
import {firebase} from '../firebase/Firebase';

import SignupView from '../components/SignupView';
import { Redirect } from 'react-router';
import { ErrorMessage, ErrorType } from '../models/Error';
import { titleSignup } from '../utils/Strings2';

type SignupContainerProps = SignupStore.SignupState &
    typeof SignupStore.actionCreators;

class SignupContainer extends  React.Component<SignupContainerProps, {isLoggedIn: boolean, errorMsgs: ErrorMessage[]}> {

    constructor(props: any) {
        super(props);
        document.title = titleSignup;


        this.onSubmit = this.onSubmit.bind(this);
        this.updateState = this.updateState.bind(this);

        this.state = {
            isLoggedIn: false,
            errorMsgs: []
        }


    }

    public componentDidMount(){
        firebase.auth().onAuthStateChanged((user) => {
           if(user){
               this.setState({isLoggedIn: true});
           }

        });
    }

    public render() {
        return  <SignupView  username={this.props.email} 
                            password={this.props.password} 
                            confirmPassword={this.props.confirmPassword} 
                            errorMsg={this.props.errorMsg} 
                            isLoggedIn={this.state.isLoggedIn}
                            errorMsgs={this.state.errorMsgs}
                            onSubmit={this.onSubmit}
                            updateState={this.updateState} />
    }

    public onSubmit() {

    
        let msgs: ErrorMessage[] = [];
        this.setState({errorMsgs: msgs});

        if(this.props.email === "") { msgs.push(new ErrorMessage(ErrorType.Email, "Please fill out your email")); }
        if(this.props.password === "") {msgs.push(new ErrorMessage(ErrorType.Password, "Please enter a password")); }
        if(this.props.confirmPassword === "") {msgs.push(new ErrorMessage(ErrorType.ConfirmPassword, "Please confirm your password")); }

        if(msgs.length > 0){
            this.setState({errorMsgs: msgs});
            return;
        }

        this.props.onSubmit(this.props.email, this.props.password, this.props.confirmPassword);
    }

    public updateState(event: React.FormEvent<HTMLInputElement>) {
        const {name, value} = event.currentTarget;
        this.props.updateState(name, value);

    }

}

const mapStateToProps = (state: ApplicationState) => state.signup;

export default connect(
    mapStateToProps,
    SignupStore.actionCreators
)(SignupContainer as any);