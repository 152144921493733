import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {postRequest} from '../utils/http';
import { BrokerSignupRequest } from '../models/Broker';
import { ErrorMessage,  ErrorType } from '../models/Error';


export interface RequestSignup {type: 'REQUEST_SIGNUP'; }
export interface RecieveSignup {type: 'RECIEVE_SIGNUP'; errorMsgs: ErrorMessage[]; }
export interface ErrorPassword {type: 'ERROR_SIGNUP'; errorMsgs: ErrorMessage[]; }
export interface UpdateCountry {type: 'UPDATE_COUNTRY'; country: string; }
export interface UpdateInput {type: 'UPDATE_INPUT', name: string, value: any };
export interface SubmitOk {type:'SUBMIT_OK', submitOk: boolean;}

export type KnownAction = RequestSignup | RecieveSignup | ErrorPassword | UpdateInput | UpdateCountry | SubmitOk;

export interface BrokerSignupState {
    firstName: string;
    lastName: string;
    brokerageName: string;
    email: string;
    country: string;
    phone: string;
    agreeTerms: boolean;
    mailList: boolean;
    errorMsgs: ErrorMessage[];
    submitOk: boolean;
}
export const actionCreators = {

    onMount: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'REQUEST_SIGNUP'});
    },

    onSubmit: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        const state = getState().brokerSignup;

        

        if(state === undefined)
            return;

        let errorMsgs: ErrorMessage[] = [];
        dispatch({type: 'ERROR_SIGNUP', errorMsgs: errorMsgs});

        if(!state.agreeTerms){
            errorMsgs.push(new ErrorMessage(ErrorType.Terms, 'You must agree to the Terms of Service'));
        }
        if( state.firstName === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.FirstName,  'Please enter your first name.'));
        }
        if( state.lastName === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.LastName, 'Please enter your last name.'));
        }
        if( state.brokerageName === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.BrokerageName,'Please enter your brokerage name.'));
        }
        if( state.country === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.Country, "Please select a country."));
        }
        if( state.email === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.Email, 'Please enter your email.'));
        }
        if( state.phone === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.Phone, 'Please enter a phone number'));
        }
        

        if(errorMsgs.length == 0){

            const signupRequest: BrokerSignupRequest = { firstName: state.firstName, lastName: state.lastName, brokerageName: state.brokerageName, country: state.country, email: state.email, phone: state.phone, mailList: state.mailList };
            postRequest<BrokerSignupRequest>(`Email/SendBrokerSignup`, signupRequest);            

            dispatch({type: 'SUBMIT_OK', submitOk: true});
        }
        else {
            dispatch({type: 'ERROR_SIGNUP', errorMsgs: errorMsgs});
        }

    },
    updateState: (name: string, value: any):  AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_INPUT', name: name, value: value});
    },
    updateCountry: (country: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_COUNTRY', country: country});
    }
};
const unloadedState = { email: "", firstName: "", lastName: "", brokerageName: "", country: "", phone:"", errorMsgs: [], agreeTerms: false, mailList: false, submitOk: false };
export const reducer: Reducer<BrokerSignupState> = (state: BrokerSignupState | undefined, incomingAction: Action): BrokerSignupState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SIGNUP':
            return unloadedState;
        case 'RECIEVE_SIGNUP':
            return {
                ...state,
                errorMsgs: action.errorMsgs
            }
        case 'ERROR_SIGNUP':
            return {
                ...state,
                errorMsgs: action.errorMsgs
            }
        case 'UPDATE_INPUT':
            return {
                ...state,
                [action.name] : action.value
            }
        case 'UPDATE_COUNTRY':
            return {
                ...state,
                country: action.country
            }
        case 'SUBMIT_OK':
            return {
                ...state,
                submitOk: action.submitOk
            }
        default:
            return state;
    }
};