import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { CurrentListingResults, Listing } from '../models/Listing';
import {postRequest} from '../utils/http'

export interface FavoritesState {
    listings: Listing[];
    listingCount: number;
    isLoading: boolean;
}


export interface RequestFavorites{type: 'REQUEST_FAVORITES'; }
export interface RecieveFavorites {type: 'RECIEVE_FAVORITES'; listingResults: CurrentListingResults;}
export interface RemoveFavorite{type: 'REMOVE_FAVORITE', listingId: number};

export type KnownAction = RequestFavorites | RecieveFavorites | RemoveFavorite;


export const actionCreators = {
    getFavorites: (userId?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'REQUEST_FAVORITES' });

        var item = window.sessionStorage.getItem("favorites");

        var favorites:any = [];
        if(item != null)
        {
            favorites = JSON.parse(item);   
        }

        postRequest<CurrentListingResults>(`favorites/Post`, favorites)
        .then(c => dispatch({type: 'RECIEVE_FAVORITES', listingResults: c}));

    },
    removeFavorite: (favorite: Listing): AppThunkAction<KnownAction> => (dispatch) => {
        
        //removal from DB comes from UserContextProvider
        var item = window.sessionStorage.getItem("favorites");

        var favorites:any = [];
        if(item != null)
        {
            favorites = JSON.parse(item);   
        }

        favorites.splice(favorites.indexOf(favorite.listingId), 1);

        window.sessionStorage.setItem("favorites", JSON.stringify(favorites));


        dispatch({type: 'REMOVE_FAVORITE', listingId: favorite.listingId});
    }


};
const unloadedState = { listings: [], isLoading: true, listingCount: 0};
export const reducer: Reducer<FavoritesState> = (state: FavoritesState | undefined, incomingAction: Action): FavoritesState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_FAVORITES':
            return {
                isLoading: true,
                listingCount: 0,
                listings: []
            }
        case 'RECIEVE_FAVORITES':
            return {
                isLoading: false,
                listings: action.listingResults.listings,
                listingCount: action.listingResults.count
            }
        case 'REMOVE_FAVORITE':
            var filtered = state.listings.filter(f => f.listingId !== action.listingId);
            
            return {
                ...state,
                listings: filtered,
                listingCount: filtered.length
                
            }
        default:
            return state;
    }

};