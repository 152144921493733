import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { BrokerProfile, BrokerContactRequest } from '../models/Broker';
import {postRequest} from '../utils/http';
import { ErrorMessage,  ErrorType } from '../models/Error';

export interface BrokerProfileState {
    profile: BrokerProfile;
    isLoading: boolean;
    isValid: boolean;
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    comments: string;
    phone:string;
    agreeTerms: boolean;
    errorMsgs: ErrorMessage[];
    submitOk: boolean;
}


export interface RequestBrokerProfiles { type: 'REQUEST_BROKER_PROFILE'; }
export interface RecieveBrokerProfiles { type: 'RECIEVE_BROKER_PROFILE'; profile: BrokerProfile; }
export interface ErrorPassword {type: 'ERROR_SIGNUP'; errorMsgs: ErrorMessage[]; }
export interface UpdateCountry {type: 'UPDATE_COUNTRY'; country: string; }
export interface UpdateInput {type: 'UPDATE_INPUT', name: string, value: any };
export interface SubmitOk {type:'SUBMIT_OK', submitOk: boolean;}

export type KnownAction = RequestBrokerProfiles | RecieveBrokerProfiles | ErrorPassword | UpdateCountry | UpdateInput | SubmitOk;


export const actionCreators = {
    getBrokerProfile: (profileId: string): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({ type: 'REQUEST_BROKER_PROFILE' });
        

        postRequest<BrokerProfile>(`BrokerProfiles/PostBrokerProfile`, profileId)
        .then(content => 
            {
                dispatch({ type: 'RECIEVE_BROKER_PROFILE', profile: content }); 
                document.title = `Japan Global Auctions | ${content.name}` 
            });
    },
    onSubmit: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        const state = getState().brokerProfile;

        if(state === undefined){
            return;
        }

        let errorMsgs: ErrorMessage[] = [];
        dispatch({type: 'ERROR_SIGNUP', errorMsgs: errorMsgs});

        if(!state.agreeTerms){
            errorMsgs.push(new ErrorMessage(ErrorType.Terms, 'You must agree to the Terms of Service'));
        }
        if( state.firstName === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.FirstName,  'Please enter your first name.'));
        }
        if( state.lastName === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.LastName, 'Please enter your last name.'));
        }
        if( state.country === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.Country, "Please select a country."));
        }
        if( state.email === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.Email, 'Please enter your email.'));
        }
        if( state.phone === "") {
            errorMsgs.push(new ErrorMessage(ErrorType.Phone, 'Please enter a phone number'));
        }
        if(state.comments === ""){
            errorMsgs.push(new ErrorMessage(ErrorType.Comment, 'Please enter a comment'));
        }

        if(errorMsgs.length == 0){

            const contactRequest: BrokerContactRequest = { firstName: state.firstName, lastName: state.lastName, country: state.country, email: state.email, phone: state.phone, brokerUri: state.profile.uri, comments: state.comments  };
            postRequest<BrokerContactRequest>(`Email/SendBrokerContact`, contactRequest);            

            dispatch({type: 'SUBMIT_OK', submitOk: true});
        }
        else {
            dispatch({type: 'ERROR_SIGNUP', errorMsgs: errorMsgs});
        }
       
        

    },
    updateState: (name: string, value: any):  AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_INPUT', name: name, value: value});
    },
    updateCountry: (country: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_COUNTRY', country: country});
    }


};
const unloadedState = { firstName: "", lastName: "", email: "", country: "", phone: "", comments: "",
                         profile: { name: "", bio: "", uri: "", website: "", id: 0, city: "", social: "", country: "", countryCode: "", zipCode: "", isIntl: false, stateProvince: "", longitude: 0.0, latitude: 0.0}, 
                        isLoading: true, isValid: true, agreeTerms: false, submitOk: false, errorMsgs: []};

export const reducer: Reducer<BrokerProfileState> = (state: BrokerProfileState | undefined, incomingAction: Action): BrokerProfileState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_BROKER_PROFILE':
            return {
                ...state,
                isLoading: true,
                profile: unloadedState.profile,
                submitOk: false
            }
        case 'RECIEVE_BROKER_PROFILE':
            return {
                ...state,
                isValid: action.profile.id !== 0,
                isLoading: false,
                profile: action.profile
            }
            case 'ERROR_SIGNUP':
                return {
                    ...state,
                    errorMsgs: action.errorMsgs
                }
            case 'UPDATE_INPUT':
                return {
                    ...state,
                    [action.name] : action.value
                }
            case 'UPDATE_COUNTRY':
                return {
                    ...state,
                    country: action.country
                }
            case 'SUBMIT_OK':
                return {
                    ...state,
                    submitOk: action.submitOk
                }
        default:
            return state;
    }

};