enum ErrorType {
    None,
    FirstName,
    LastName,
    FullName,
    Email,
    Phone,
    Country,
    BrokerageName,
    Message,
    BugLocation,
    BugDescription,
    Password,
    ConfirmPassword,
    Terms,
    Comment
}

class ErrorMessage {

    constructor(errorTp: ErrorType, errorDesc: string){
        this.errorDescription = errorDesc;
        this.errorType = errorTp;
    }
    
    errorDescription: string;
    errorType: ErrorType;
}

export {ErrorType, ErrorMessage}