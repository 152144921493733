import * as React from 'react';
import ContactUsView from '../components/ContactUsView';
import { titleContactUs } from '../utils/Strings2';


class ContactUsContainer extends React.Component {

    constructor(props: any){
        super(props);
        document.title = titleContactUs;
    }

    public render() {
        return <ContactUsView />
    }

}

export default ContactUsContainer;