import * as React from 'react';
import * as BrokerDashboard from '../store/BrokerDashboardStore';
import BrokerDashboardView from './BrokerDashboardView';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { UserContext } from '../models/UserContext';
import firebase from 'firebase';
import { ConnectionRequest } from '../models/ConnectionRequest';



type BrokerDashboardContainerProps = BrokerDashboard.BrokerDashboardState &
typeof BrokerDashboard.actionCreators;

class BrokerDashboardContainer extends React.Component<BrokerDashboardContainerProps> {

    constructor(props: BrokerDashboardContainerProps){
        super(props);
        this.acceptConnection = this.acceptConnection.bind(this);
        this.removeConnection = this.removeConnection.bind(this);
    }

    public componentDidMount()
    {
        firebase.auth().onAuthStateChanged((user) => {
            if(user != null)
            {
                this.props.getConnectionRequests(1, user.uid);
                this.props.getBidRequests(1, user.uid);
                this.props.getConnections(1, user.uid);
            }
        });
    }

    public render()
    {
        return  (this.props.isLoadingBidRequests || this.props.isLoadingConnectionRequests || this.props.isLoadingConnections) 
        ?  <div className="loading-spinner"></div> 
        :  <BrokerDashboardView acceptConnection={this.acceptConnection} 
                                removeConnection={this.removeConnection} 
                                connectionRequests={this.props.connectionRequests}
                                bidRequests={this.props.bidRequests}
                                connections={this.props.connections} />
    }

    public acceptConnection(e: React.MouseEvent<any, MouseEvent>, connectionRequest: ConnectionRequest)
    {
        e.preventDefault();
        this.props.acceptConnectionRequest(connectionRequest);
    }
    public removeConnection(e: React.MouseEvent<any, MouseEvent>, connectionRequest: ConnectionRequest)
    {
        e.preventDefault();
        this.props.rejectConnectionRequest(connectionRequest);
    }
}

const mapStateToProps = (state: ApplicationState) => state.brokerDashboard;

export default connect(
    mapStateToProps,
    BrokerDashboard.actionCreators
)(BrokerDashboardContainer as any);

BrokerDashboardContainer.contextType = UserContext;