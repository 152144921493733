import * as React from 'react';
import { Favorite } from './Favorite';
import { Listing } from './Listing';
import { UserInfo } from './UserInfo';

export interface UserType  
{
    getFavorites(): any,
    getUserInfo(userId: string): void,
    addFavorite(event: React.MouseEvent<HTMLAnchorElement>, listing: Listing): any,
    removeFavorite(event: React.MouseEvent<HTMLAnchorElement>, listing: Listing): any,
    setUser(user: firebase.User): any,
    userId: string,
    user: firebase.User|undefined,
    favorites: Favorite[],
    clear(): any,
    userInfo: UserInfo

};

export const UserContext = React.createContext<UserType>({
    getFavorites: () => {}, 
    getUserInfo: (userId: string) => {},
    clear: () => {},
    addFavorite: (event: React.MouseEvent<HTMLAnchorElement>, listing: Listing) => {}, 
    removeFavorite: (event: React.MouseEvent<HTMLAnchorElement>, listing: Listing) => {}, 
    userId: "", 
    user: undefined, 
    setUser: (user: firebase.User) => {}, 
    favorites: [],
    userInfo: {} as UserInfo}
);

