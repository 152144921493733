import * as React from 'react';

import { Listing } from '../models/Listing';
import GearInfoConverter from '../converters/GearInfoConverter';
import RelatedVehiclesContainer from '../components/RelatedVehiclesContainer';
import BadgeContainer from '../components/BadgeContainer';
import { Container, Row, Col, Button, Table, Form, FormGroup, Label, Input, FormText, ListGroup, ListGroupItem} from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CopyToClipboard  from 'react-copy-to-clipboard';
import * as _ from 'lodash';
import { useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../models/UserContext';
import BrokerRequest, { BrokerProfile } from '../models/Broker';
import {CountryDropdown} from 'react-country-region-selector';
import GoogleMapContainer from '../components/GoogleMapContainer';
import { Marker } from 'react-google-maps';
import GenericModalContainer, { ModalType } from './GenericModalContainer';
import LoginContainer from './LoginContainer';
import  {ErrorMessage,  ErrorType } from '../models/Error';
import { reduce } from 'lodash';
import { AuctionMetaTags } from './MetaTags';
import {InputGroup, InnerInputGroup, CountryDropdownGroup} from '../components/InputGroup';
import { validationErrorFullName, validationErrorEmail, validationErrorCountry } from '../utils/Strings2';

export interface ListingPageProps {
    listing: Listing;
    imageSrc: string[] | undefined;
    brokerRequest: BrokerRequest;
    brokerProfiles: BrokerProfile[];
    isLinkCopied: boolean;
    isLoading: boolean;
    isFavorited: boolean;
    selectedBrokerId: number;
    latitude: number;
    longitude: number;
    errorMsg: ErrorMessage[];
    share(): any;
    resetLinkState(): any;
    removeBroken(event: React.SyntheticEvent<HTMLImageElement>): any; 
    updateBrokerRequest(event: React.FormEvent<HTMLInputElement>): any;
    updateSelectedBroker(event: React.MouseEvent<HTMLButtonElement>, brokerId: number): any;
    updateCountry(value: string, e: React.ChangeEvent<Element> | undefined): any;
    sendBrokerRequest(): any;
    onValidate(): boolean;
}



const ListingPage = (props: ListingPageProps) => {

        const {addFavorite, removeFavorite, user} = useContext(UserContext);

        
        const [loginModal, setLoginModal] = useState(false);
        const toggleLogin = () => setLoginModal(!loginModal);

        const item = window.sessionStorage.getItem("favorites");

        var favorites:any = [];
        if(item != null)
        {
            favorites = JSON.parse(item);
        }

        const favoriteHandler = favorites.includes(props.listing.listingId)? <a href="#" className="isFavorited" onClick={(e) => { removeFavorite(e, props.listing) }} ></a> : <a href="#" className="favorite" onClick={(e) => { if(!user){ e.preventDefault(); toggleLogin(); } else { addFavorite(e, props.listing)}}} ></a>;
        const favoriteLabel = favorites.includes(props.listing.listingId) ? <Label className="labelFavorited animated fadeInLeft">Favorited!</Label> : null;

        var images;
        if(props.imageSrc !== undefined)
        {
            images = props.imageSrc.map((im, index) => {
                return <div key={index}>
                            <img className="grid-img" onLoad={props.removeBroken} src={im} alt={`photo${index}`} />
                        </div>
            });
        }

        const usdToJpyRate = .009;
        const usdFormatter = Intl.NumberFormat('en-US',  {style: 'currency', currency: 'USD', minimumFractionDigits: 0});
        const jpyFormatter = Intl.NumberFormat('en-US',  {style: 'currency', currency: 'JPY', minimumFractionDigits: 0 });

        const [modal, setModal] = useState(false);
        const toggle = () => setModal(!modal);

        const closeLogin = () => {
            setLoginModal(false);
        }






        const modelName = (props.listing.modelCodeName != null) ? props.listing.modelCodeName.toUpperCase() : null;

        return props.isLoading ? 
            <div className="loading-spinner"></div>   
            :
            <Container className="page-content listing-page">
                <AuctionMetaTags listing={props.listing} />
                <Row>
                    <Col className="mobile-intro d-block d-lg-none"> {/* this div only visible on mobile/tablet, so the headings appear above the images */}
                        <Row>
                            <Col xs={12}>
                                <h6 className="mainheading">{props.listing.yearInfo.year} {props.listing.makeAndModelInfo.make}</h6>
                                <h3 className="mainheading">{props.listing.makeAndModelInfo.model} {modelName}</h3>
                            </Col>
                        </Row>
                        <Table>
                            <tbody>
                                <tr>
                                    <td><BadgeContainer score={props.listing.scoreInfo.score}
                                        outerText="Auction Score: "
                                        color="primary" /></td>
                                    <td><BadgeContainer score={props.listing.exteriorScore.score}
                                        outerText="Ext. Score: "
                                        isIntExtScore={true} /></td>
                                    <td><BadgeContainer score={props.listing.interiorScore.score}
                                        outerText="Int. Score: "
                                        isIntExtScore={true}
                                    /></td>
                                </tr>
                            </tbody>
                        </Table> 
                    </Col>
                    <Col lg={6} className="temp-images-container">
                        <div className="image-viewer">
                            {favoriteHandler}
                            {favoriteLabel}
                            <Carousel showIndicators={false} showStatus={false} infiniteLoop={true} useKeyboardArrows={true} dynamicHeight>
                                {images}
                            </Carousel>
                        </div>
                        
                            {/* { props.latitude === 0.0 && props.longitude === 0.0 ? null :
                            <div className="google-map-listing-page">
                                <GoogleMapContainer zoomLevel={6} latitude={props.latitude} longitude={props.longitude}>
                                    <Marker position={{lat: props.latitude, lng: props.longitude}}  /> 
                                </GoogleMapContainer>
                                </div>
                            } */}
                        

                    </Col>
                    <Col lg={{ offset: 1 }}></Col>
                    <Col lg={5} className="listing-content-full">
                        <div className="d-none d-lg-block"> {/* this div only visible on desktop, so the headings appear in the right column */}
                            <h6 className="mainheading">{props.listing.yearInfo.year} {props.listing.makeAndModelInfo.make}</h6>
                            <h3 className="mainheading">{props.listing.makeAndModelInfo.model} {modelName}</h3>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td><BadgeContainer score={props.listing.scoreInfo.score} 
                                                            outerText="Auction Score: " 
                                                            color="primary" /></td>
                                    </tr>
                                </tbody>
                            </Table>                              
                        </div>
                        <div className="ctaButtons">
                            {/* <Button className="btn-lg d-block d-sm-inline full-width" color="dark" onClick={toggle}>Contact a broker</Button> */}
                            <CopyToClipboard onCopy={_.throttle(props.share, 3500)} text={'https://www.japanglobalauctions.com/auction/' + props.listing.uid} >
                                <Button className="btn-lg btn-light d-block d-sm-inline full-width">Copy link</Button>
                            </CopyToClipboard>
                            {props.isLinkCopied ? <span className="copyLinkText" onAnimationEnd={props.resetLinkState}>Link copied</span> : null }
                        </div>
                        <br />
                        <Table striped>
                            <tbody>
                                
                                <tr>
                                    <td className="tableLabel">Odometer</td>
                                    <td>{props.listing.mileageInfo.mileage} km</td>
                                </tr>
                                <tr>
                                    <td className="tableLabel">Transmission</td>
                                    <td><GearInfoConverter gearType={props.listing.gearInfo.type} numberOfGears={props.listing.gearInfo.numGears} /></td>
                                </tr>
                                <tr>
                                    <td className="tableLabel">Displacement</td>
                                    <td>{props.listing.displacementInfo.displacement} cc</td>
                                </tr>
                                <tr>
                                    <td className="tableLabel">Model Code</td>
                                    <td>{props.listing.modelCode}</td>
                                </tr>
                                <tr>
                                    <td className="tableLabel">Location</td>
                                    <td>{props.listing.auctionLocationInfo.location}</td>
                                </tr>
                                <tr>
                                    <td className="tableLabel">Lot No.</td>
                                    <td>{props.listing.auctionLocationInfo.lotNumber}</td>
                                </tr>
                                <tr>
                                    <td className="tableLabel">Starting Bid</td>
                                    <td> {jpyFormatter.format(props.listing.startPriceInfo.price)} YEN ({usdFormatter.format(props.listing.startPriceInfo.price * usdToJpyRate)} USD)</td>
                                </tr>
                                <tr>
                                    <td className="tableLabel">Auction Date</td>
                                    <td>{new Date(props.listing.auctionLocationInfo.dateInfo.dateTime).toLocaleDateString()} {new Date(props.listing.auctionLocationInfo.dateInfo.dateTime).toLocaleTimeString()}</td>
                                </tr>
                                
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {/* <RelatedVehiclesContainer  listing={props.listing} /> */}
                <GenericModalContainer 
                    isOpen={modal} 
                    toggle={toggle} 
                    type={ModalType.OkCancel}
                    onSubmit={props.sendBrokerRequest}
                    validateModal={props.onValidate}
                    okButtonTextOverride="Submit"
                    title={`Contact a broker - ${props.listing.yearInfo.year} ${props.listing.makeAndModelInfo.make} ${props.listing.makeAndModelInfo.model}`}
                    classNameOverride="contact-broker-modal"  >

                    <Col md="6">
                    Let's get you connected with a broker to begin the bidding process. We'll need the following information to get started:
                    <br/>
                    <br />
                    <Form>
                        <InputGroup for="contactName"
                                    name="Full name" type="text" id="name" onChange={props.updateBrokerRequest}
                                    errorMsgs={props.errorMsg} errorMsg={validationErrorFullName} errorType={ErrorType.FullName} />
                        <InputGroup for="email"
                            name="Email" id="email" type="text" placeholder="email@example.com" onChange={props.updateBrokerRequest}
                            errorMsg={validationErrorEmail} errorMsgs={props.errorMsg} errorType={ErrorType.Email} />

                        <CountryDropdownGroup for="country" name="Country of Import"
                                        value={props.brokerRequest.country} id="country" 
                                        onCountryChange={props.updateCountry} errorMsgs={props.errorMsg}
                                        errorMsg={validationErrorCountry} errorType={ErrorType.Country}>
                            <FormText color="muted">
                                Please select the country you're importing to—this will help us get you the right broker.
                            </FormText>
                        </CountryDropdownGroup>

                        <InputGroup for="message" type="textarea" onChange={props.updateBrokerRequest}
                                        name="Comments" id="comments" placeholder="Write your message here..."  />
                                        


                        {/* <FormGroup>
                            <Label for="contactName">Full name</Label>
                            <Input type="text" name="name" id="contactName" className={props.errorMsg.find((msg) => msg.errorType === ErrorType.FullName) ? "validation-error-input" : ""} placeholder="Your first and last name" onChange={props.updateBrokerRequest} required={true} />
                            {props.errorMsg.find((msg) => msg.errorType === ErrorType.FullName) && <Label className="validation-error-label">Please enter your name.</Label>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="contactEmail">Email address</Label>
                            <Input type="email" name="email" id="contactEmail" className={props.errorMsg.find((msg) => msg.errorType === ErrorType.Email) ? "validation-error-input" : ""} placeholder="email@example.com" onChange={props.updateBrokerRequest} />
                            {props.errorMsg.find((msg) => msg.errorType === ErrorType.Email) && <Label className="validation-error-label">Please enter a valid email address.</Label>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleSelect">Country of Import</Label>
                            
                            <CountryDropdown classes={props.errorMsg.find((msg) => msg.errorType === ErrorType.Country) ? "validation-error-input" : ""} value={props.brokerRequest.country} onChange={props.updateCountry} />
                            
                            <FormText color="muted">
                                Please select the country you're importing to—this will help us get you the right broker.
                            </FormText>
                            {props.errorMsg.find((msg) => msg.errorType === ErrorType.Country) && <Label className="validation-error-label">Please select a country.</Label>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleText">Comments</Label>
                            <Input type="textarea" name="comments" id="contactText" onChange={props.updateBrokerRequest} />
                        </FormGroup> */}
                        <FormText color="muted">
                            By filling out this form you're consenting to be contacted by one of our affiliated brokers.
                        </FormText>
                        
                    </Form>
                    </Col>
                    <Col md="6" className="broker-selection">
                        <h4>Select a broker (optional)</h4>

                        { props.brokerProfiles.length === 0 ? 
                        <div>Thanks for your interest. Currently there are no brokers in your region, but we're adding more brokers all time. Fill out your contact details on the left and we'll connect you to a broker that can work with you. </div> 
                        : <ListGroup>
                        {props.brokerProfiles.map((broker: BrokerProfile) =>
                            <ListGroupItem key={broker.id} active={+props.brokerRequest.brokerId === broker.id} action > 
                                <Row>
                                    <Col xs={4}>
                                        <img className="broker-profile-img" src={`https://static.japanglobalauctions.com/profiles/${broker.uri}/profile.jpg`} alt="profile" />
                                    </Col>
                                    <Col>
                                        {/*<Input type="radio" name="brokerId" value={broker.id} checked={+props.brokerRequest.brokerId === broker.id} onChange={props.updateSelectedBroker} /> */}
                                        <h4>{broker.name}</h4>
                                        <p>{broker.city}, {broker.countryCode}</p>
                                    </Col>
                                </Row>
                            </ListGroupItem>

                            
                        )}
                        </ListGroup>
                        }
                    </Col>
                    </GenericModalContainer>
                    <GenericModalContainer isOpen={loginModal} 
                                   toggle={toggleLogin} 
                                   type={ModalType.None} 
                                   title="Log in" >
                        <LoginContainer onLoginSuccessful={closeLogin} />
                    </GenericModalContainer>
                
            </Container>
        
            
    }
    

export default ListingPage;