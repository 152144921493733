
export function sanitizePathname(str: string) : string {
    return str.replace(/\//g, '-').substr(1, str.length - 1);
}

export const privacyPolicyInfo = "some privacy policy here!";
export const termsOfServiceInfo = "some terms of service here!";

export const validationErrorFirstName = "Please enter your first name.";
export const validationErrorLastName = "Please enter your last name.";
export const validationErrorFullName = "Please enter your name.";

export const validationErrorBrokerageName = "Please enter your brokerage name.";
export const validationErrorEmail = "Please enter a valid email address.";
export const validationErrorCountry = "Please select a country.";
export const validationErrorPhone = "Please enter a valid phone number.";
export const validationErrorAgreeTerms = "You must agree to the terms of service."
export const validationErrorMessage = "Please enter a message.";
export const validationErrorBugDescription = "Please enter a bug description.";
export const validationErrorBugLocation = "Please enter a bug location.";
export const validationErrorPassword ="Please enter your password.";
export const validationErrorConfirmPassword = "Please confirm your password.";
export const validationErrorComments = "Please enter a comment.";

export const titleLoading = "Japan Global Auctions | Loading...";
export const titleBrokers = "Japan Global Auctions | All Brokers";
export const titleAuctions = "Japan Global Auctions | Auctions";
export const titleHome = "Japan Global Auctions | Find, buy and import your dream car";
export const titleContactUs = "Japan Global Auctions | Contact Us";
export const titleBecomeABroker = "Japan Global Auctions | Become A Broker";
export const titleFavorites = "Japan Global Auctions | Favorites";
export const titleAlerts = "Japan Global Auctions | Alerts";
export const titleSignup = "Japan Global Auctions | Signup";
export const titleLogin = "Japan Global Auctions | Login";
