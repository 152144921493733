import React, { useContext } from 'react';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as LoginStore from '../store/LoginStore';
import {firebase} from '../firebase/Firebase';

import LoginView from '../components/LoginView';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { titleLogin } from '../utils/Strings2';

export interface MyLoginProps {
    onLoginSuccessful?(): any;
}

type LoginContainerProps = LoginStore.LoginState &
    typeof LoginStore.actionCreators & RouteComponentProps & MyLoginProps;

class LoginContainer extends  React.Component<LoginContainerProps, {isLoggedIn: boolean}> {

    constructor(props: LoginContainerProps) {
        super(props);
        document.title = titleLogin;

        this.onSubmit = this.onSubmit.bind(this);
        this.updateState = this.updateState.bind(this);
        

        this.state = {
            isLoggedIn: false
        }

    }

    public componentDidMount(){
        firebase.auth().onAuthStateChanged((user) => {
           if(user){
               this.setState({isLoggedIn: true});
               if(this.props.onLoginSuccessful){
                this.props.onLoginSuccessful();
               }
           }

        });
    }

    public render() {

        return ( <LoginView  username={this.props.email} 
                            password={this.props.password} 
                            errorMsg={this.props.errorMsg} 
                            errorMsgs={this.props.errorMsgs}
                            isLoggedIn={this.state.isLoggedIn}
                            onSubmit={this.onSubmit}
                            updateState={this.updateState} />
            );
    }

    public onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        this.props.onSubmit(this.props.email, this.props.password);
    }

    public updateState(event: React.FormEvent<HTMLInputElement>) {
        const {name, value} = event.currentTarget;
        this.props.updateState(name, value);

    }

}

const mapStateToProps = (state: ApplicationState, ownProps: MyLoginProps) => state.login;

export default connect(
    mapStateToProps,
    LoginStore.actionCreators
)(LoginContainer as any);