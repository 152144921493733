import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import User from '../models/User';

const firebaseConfig = {
  apiKey: "AIzaSyC-6Lvx9N54I8r6NCMcDvS6p2blsURVHWM",
  authDomain: "japanauctiontool.firebaseapp.com",
  databaseURL: "https://japanauctiontool.firebaseio.com",
  projectId: "japanauctiontool",
  storageBucket: "japanauctiontool.appspot.com",
  messagingSenderId: "89072923284",
  appId: "1:89072923284:web:9dff6bfeaa4f6893637a81",
  measurementId: "G-XQCYDVB8HL"
  };

var userSession: User = new User();

try {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics().logEvent("site_visit");
}
catch(err) { }

export  {firebase, userSession };
