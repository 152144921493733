import React from 'react';
import Home from '../components/Home';
import * as HomeStore  from '../store/HomeStore';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { UserContext } from '../models/UserContext';
import { titleHome } from '../utils/Strings2';

type HomeContainerProps = HomeStore.HomeState &
    typeof HomeStore.actionCreators;

class HomeContainer extends React.Component<HomeContainerProps, {}>
{

    constructor(props: HomeContainerProps){
        super(props);
        document.title = titleHome;
    }

    public componentDidMount() {
        this.props.getRecentlyAdded();


    }

    public render()
    {
        return <Home listings={this.props.listings} isLoading={this.props.isLoading} />
    }
};

const mapStateToProps = (state: ApplicationState) => state.home;

export default connect(
    mapStateToProps,
    HomeStore.actionCreators
)(HomeContainer as any);
