import { Favorite } from './Favorite';


class User {
    favorites: Favorite[];

    constructor(){
        this.favorites = [];
    }

    public getFavorites(userId: string){
    }

    public addFavorite() {

    }

    public removeFavorite() {

    }


}

export default User;
