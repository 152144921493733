import React from 'react';
import { Listing } from '../models/Listing';
import { Link } from 'react-router-dom';
import GearInfoConverter from '../converters/GearInfoConverter';
import { ScoreInfoConverter } from '../converters/ScoreInfoConverter';
import { Badge, Button, Label } from 'reactstrap';
import { useContext } from 'react';
import { UserContext } from '../models/UserContext';
import GenericModalContainer, { ModalType } from './GenericModalContainer';
import LoginContainer from './LoginContainer';

interface ListingItemProps {
    listing: Listing;
    userId: string;
    onFavorite(event: React.MouseEvent<HTMLAnchorElement>): any;
    isFavorited: boolean;
}

const ListingItem = (props: ListingItemProps) => {
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);

    const item = window.sessionStorage.getItem("favorites");

    var favorites:any = [];
    if(item != null)
    {
        favorites = JSON.parse(item);
    }


    const modelName = (props.listing.modelCodeName != null) ? props.listing.modelCodeName.toUpperCase() : null;
    const {addFavorite, removeFavorite, user} = useContext(UserContext);
    const favoriteHandler = favorites.includes(props.listing.listingId) ? <a href="#" className="isFavorited" onClick={(e) => {removeFavorite(e, props.listing)}} ></a> : <a href="#" className="favorite" onClick={(e) => { if(!user){ e.preventDefault(); toggle(); } else { addFavorite(e, props.listing)}}} ></a>;
    const favoriteLabel = favorites.includes(props.listing.listingId) ? <Label className="labelFavorited animated fadeInLeft">Favorited!</Label> : null;

    const closeLogin = () => { setModal(false); }

    
    return (
        
            <div className="grid-item-container">
                {favoriteHandler}
                <Link to={{ pathname: `/auction/${props.listing.uid}`}} className="listing-link">
                <div className="grid-img-container">
                    {favoriteLabel}
                    <img className="grid-img" src={`auction/${props.listing.listingId}-1.jpg`} alt="photo1" />
                </div>
                <div className="grid-item-content">
                    <h6>{props.listing.yearInfo.year} {props.listing.makeAndModelInfo.make}</h6>
                    <h3>{props.listing.makeAndModelInfo.model} {modelName}</h3>
                    <div className="row">
                        <div className="col-lg-6">
                            <span className="mileage">{props.listing.mileageInfo.mileage } km</span>
                        </div>
                        <div className="col-lg-6">
                                <span className="transmission"><GearInfoConverter gearType={props.listing.gearInfo.type} numberOfGears={props.listing.gearInfo.numGears} /></span>
                        </div>
                        <div className="col-12">
                            <span className="score">Score: <Badge color="light" pill><ScoreInfoConverter score={props.listing.scoreInfo.score} /></Badge></span>
                        </div>
                    </div>
                </div>
                </Link>
                <GenericModalContainer isOpen={modal} 
                                   toggle={toggle} 
                                   type={ModalType.None} 
                                   title="Log in" >
                <LoginContainer onLoginSuccessful={closeLogin} />
            </GenericModalContainer>
            </div>
                
        
        );
}

export default ListingItem;
