import * as React from 'react';
import Layout from './components/Layout';
import HomeContainer from './components/HomeContainer';
import ListingContainer from './components/ListingContainer';
import ListingPageContainer from './components/ListingPageContainer';
import SignupContainer from './components/SignupContainer';
import LogoutContainer from './components/LogoutContainer';
import LoginContainer from './components/LoginContainer';
import FavoritesContainer from './components/FavoritesContainer';
import AlertsContainer from './components/AlertsContainer';
import BrokersListContainer from './brokers/BrokersListContainer';
import BrokerSignupContainer from './components/BrokerSignupContainer';
import BrokerProfileContainer from './brokers/BrokerProfileContainer';
import BrokerDashboardContainer from './brokers/BrokerDashboardContainer';
import ContactUsContainer from './components/ContactUsContainer';
import View404 from './components/View404';
import { UserContext } from './models/UserContext';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import './custom.css'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserInfo } from './models/UserInfo';
import { getRequest, postRequest } from './utils/http';

export default class App extends React.Component {

    constructor(props: any){
        super(props);

        toast.configure({transition: Slide});
    }

    public componentDidMount() {
        var localStorage = window.localStorage
        var ipAddress =  localStorage.getItem("ipAddress");
        var countryCode = localStorage.getItem("clientCountryCode");
        
        if(countryCode == null || ipAddress == null)
        {
            getRequest<UserInfo>(`user/GetGeolocationInfo`)
            .then(userInfo => {
                localStorage.setItem("ipAddress", userInfo.ipAddress);
                localStorage.setItem("clientCountryCode", userInfo.country);
            });
            //send request

            //localStorage.setItem("ipAddress", response.ipAddress);
            //localStorage.setItem("clientCountryCode", response.countryCode);
        }

        firebase.auth().onAuthStateChanged((user) => {
        
            if(user != null){

                const {getFavorites, setUser, getUserInfo} = this.context;
  
                setUser(user);
                getFavorites();
                getUserInfo(user.uid);
                
     
            }
            else {
                const {clear} = this.context;
                clear();
            }

        });
    }

    public render() {
    return (
        <Layout>
            <Switch>
                <Route exact path='/' component={HomeContainer} />
                <Route exact path='/auctions' component={ListingContainer} />  
                <Route path='/auction/:uid?' component={ListingPageContainer} />
                <Route exact path='/accounts/signup' component={SignupContainer} />
                <Route exact path='/accounts/logout' component={LogoutContainer} />
                {/* <Route exact path='/accounts/login' component={LoginModal} /> */}
                <Route exact path='/favorites' component={FavoritesContainer} />
                {/* <Route exact path='/alerts' component={AlertsContainer} />
                <Route exact path='/brokers/' component={BrokersListContainer} />
                <Route exact path='/brokers/signup' component={BrokerSignupContainer} /> */}
                <Route exact path='/contact' component={ContactUsContainer} />
                <Route exact path='/dashboard' component={BrokerDashboardContainer} />
                <Route path='/broker/:uri?' component={BrokerProfileContainer} />
                <Route path="" component={View404} /> 

            </Switch>
        </Layout>
    );
    }
}

App.contextType = UserContext;


