import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {firebase} from '../firebase/Firebase';
import { ErrorMessage,  ErrorType } from '../models/Error';


export interface RequestSignup {type: 'REQUEST_LOGIN'; }
export interface RecieveSignup {type: 'RECIEVE_LOGIN'; errorMsg: string; }
export interface ErrorPassword {type: 'ERROR_LOGIN'; errorMsg: string; }
export interface ERrorInput {type: 'ERROR_INPUT', errorMsgs: ErrorMessage[];}
export interface UpdateInput {type: 'UPDATE_INPUT', name: string, value: string};

export type KnownAction = RequestSignup | RecieveSignup | ErrorPassword | UpdateInput | ERrorInput;

export interface LoginState {
    email: string;
    password: string;
    errorMsg: string;
    errorMsgs: ErrorMessage[];
}
export const actionCreators = {
    onSubmit: (email: string, password: string): AppThunkAction<KnownAction> => (dispatch) => {

        let errorMsgs: ErrorMessage[] = [];
        dispatch({type: 'ERROR_INPUT', errorMsgs: errorMsgs});

        if(email === ""){
            errorMsgs.push(new ErrorMessage(ErrorType.Email, ""));
        }
        if(password == ""){
            errorMsgs.push(new ErrorMessage(ErrorType.Password, ""));
        }

        if(errorMsgs.length > 0) {
            dispatch({type: 'ERROR_INPUT', errorMsgs: errorMsgs});
            return;
        }

        
        
        
        firebase.auth().signInWithEmailAndPassword(email, password).catch((error: firebase.auth.Error) => {
            
                const errorCode = error.code;
                if(errorCode !== ''){
                    dispatch({type: 'ERROR_LOGIN', errorMsg: error.message});
                }
                else {
                    
                }


        });
        dispatch({type:'RECIEVE_LOGIN', errorMsg: ""});
    },
    updateState: (name: string, value: string):  AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'UPDATE_INPUT', name: name, value: value});
    }
};
const unloadedState = { email: "", password: "", errorMsg: "", errorMsgs: [] };
export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_LOGIN':
            return {
                ...state,
                errorMsg: ""
            }
        case 'RECIEVE_LOGIN':
            return {
                ...state,
                errorMsg: action.errorMsg
            }
        case 'ERROR_LOGIN':
            return {
                ...state,
                errorMsg: action.errorMsg
            }
        case 'ERROR_INPUT':
            return {
                ...state,
                errorMsgs: action.errorMsgs
            }
        case 'UPDATE_INPUT':
            return {
                ...state,
                [action.name] : action.value
            }
        default:
            return state;
    }

};