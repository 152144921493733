import * as React from 'react';
import GenericModalView from '../components/GenericModalView';

export enum ModalType
{
    Ok,
    OkCancel,
    None
}

export interface GenericModalProps
{
    isOpen: boolean;
    title: string;
    okButtonTextOverride?: string;
    type: ModalType;
    children?: React.ReactNode;
    toggle(): void;
    onSubmit?(): void;
    validateModal?(): boolean;
    classNameOverride?: string;
}

class GenericModalContainer extends React.Component<GenericModalProps> {

    public constructor(props: GenericModalProps){
        super(props);
    }

    public render(){
        return <GenericModalView isOpen={this.props.isOpen} 
                                 title={this.props.title} 
                                 okButtonTextOverride={this.props.okButtonTextOverride} 
                                 type={this.props.type} 
                                 toggle={this.props.toggle}  
                                 onSubmit={this.props.onSubmit}
                                 validateModal={this.props.validateModal} 
                                 children={this.props.children}
                                 classNameOverride={this.props.classNameOverride}  />
    }
}

export default GenericModalContainer;