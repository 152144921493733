import React from 'react';
import {FormGroup, Form, Label, Input, FormText, Button, Container, Row, Col} from 'reactstrap';
import { SignupMetaTags } from './MetaTags';
import { useHistory } from 'react-router-dom';
import { ErrorMessage,  ErrorType } from '../models/Error';
import { validationErrorEmail, validationErrorPassword, validationErrorConfirmPassword } from '../utils/Strings2';
import { InputGroup } from './InputGroup';

export interface SignupViewProps {
    onSubmit(): any,
    updateState(event: React.FormEvent<HTMLInputElement>): any,
    username: string,
    password: string,
    confirmPassword: string,
    errorMsg: string,
    errorMsgs: ErrorMessage[],
    isLoggedIn: boolean
}

const SignupView = (props: SignupViewProps) => {

    const history = useHistory();

    if(props.isLoggedIn)
    {
        if(history.location.pathname === "/"){
            window.location.reload();
        }
        else {
            history.push("/");
        }

        return null;
    }




    return (
   <Container>
       <SignupMetaTags />
       <Row>
            <Col md={6} lg={6}>
                <h1>Save your favorite cars, create custom alerts, and more</h1>
                <p>Create your free account and you'll be able to save auctions to your favorites page as well as create email alerts for your custom search criteria. That way you'll be the first to know about rare finds!</p>
           </Col>
            <Col md={{ size: 5, offset: 1 }} lg={{ size: 4, offset: 1}}>
               <h2>Sign up, it's free</h2>
                <Form>
                    <InputGroup for="email"
                                name="Email" id="email" type="text" placeholder="email@example.com" onChange={props.updateState}
                                errorMsg={validationErrorEmail} errorMsgs={props.errorMsgs} errorType={ErrorType.Email} />

                    <InputGroup for="userPassword"
                                name="Password" id="password" type="password" placeholder="Password" onChange={props.updateState}
                                errorMsg={validationErrorPassword} errorMsgs={props.errorMsgs} errorType={ErrorType.Password} />

                    <InputGroup for="userConfirmPassword"
                                name="Confirm Password" id="confirmPassword" type="password" placeholder="Confirm Password" onChange={props.updateState}
                                errorMsg={validationErrorConfirmPassword} errorMsgs={props.errorMsgs} errorType={ErrorType.ConfirmPassword} />
                    
                    {props.errorMsg && <div className="validation-error-label" >{props.errorMsg}</div>}

                    <Button color="dark" onClick={props.onSubmit} block>Create account</Button>
                </Form>
            </Col>
        </Row>
    </Container>
    );
}

export default SignupView;