import * as React from 'react';
import { Badge} from 'reactstrap';
import { ScoreInfoConverter } from '../converters/ScoreInfoConverter';
import { BadgeProps } from './BadgeContainer';

const BadgeView = (props : BadgeProps) => {

    return ( <span>
                {props.outerText}
                <Badge color={props.color} pill>
                    <ScoreInfoConverter score={props.score} />
                </Badge>
            </span>

    );

}


export default BadgeView;