import React, { useState } from 'react';
import {FormGroup, Form, Label, Input, Button, Container, Row, Col} from 'reactstrap';
import { UserContext } from '../models/UserContext';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorMessage,  ErrorType } from '../models/Error';
import { InputGroup } from './InputGroup';
import { validationErrorEmail, validationErrorPassword } from '../utils/Strings2';

export interface LoginViewProps {
    onSubmit(e: React.FormEvent<HTMLFormElement>): any,
    updateState(event: React.FormEvent<HTMLInputElement>): any,
    username: string,
    password: string,
    errorMsg: string,
    errorMsgs: ErrorMessage[],
    isLoggedIn: boolean
}

const LoginView = (props: LoginViewProps) => {

    const history = useHistory();

    if(props.isLoggedIn)
    {
        window.location.reload();
        return null;
    }
    
    return (
        <Container>
            <Row>
                <Col>
                    <h1>Log in</h1>
                    <p>Don't have an account yet? <a href="/accounts/signup">Create one now</a></p>
                    <Form onSubmit={props.onSubmit}>
                        <InputGroup for="email"
                                        name="Email" id="email" type="text" placeholder="email@example.com" onChange={props.updateState}
                                        errorMsg={validationErrorEmail} errorMsgs={props.errorMsgs} errorType={ErrorType.Email} />
                        <InputGroup for="userPassword"
                                        name="Password" id="password" type="password" placeholder="Password" onChange={props.updateState}
                                        errorMsg={validationErrorPassword} errorMsgs={props.errorMsgs} errorType={ErrorType.Password} />

                        {props.errorMsg && <div className="validation-error-label">{props.errorMsg}</div>}

                        <Button color="dark"  type="submit" block>Submit</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )}
    


export default LoginView;