import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Label, Input, Button, Badge } from 'reactstrap';
import ReactDOM from 'react-dom';
import { Listing } from '../models/Listing';
import { Redirect } from 'react-router';
import { UserContext } from '../models/UserContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import GearInfoConverter from '../converters/GearInfoConverter';
import ScoreInfoConverter from '../converters/ScoreInfoConverter';

export interface FavoritesViewProps {
    isLoading: boolean,
    favorites: Listing[]
    removeFavorite(event: React.MouseEvent<HTMLButtonElement>, favorite: Listing): any
}

const FavoritesView = (props: FavoritesViewProps) => {

    const {removeFavorite} = useContext(UserContext);


   return ( props.isLoading ? <div className="loading-spinner"></div> :
    <div className="grid-container">
        {props.favorites.map((favorite: Listing) => (
            
            <div key={favorite.uid} className="grid-item">
                <Link to={{ pathname: `/auction/${favorite.uid}` }} className="listing-link">
                    <div className="grid-item-container">
                        <div className="grid-img-container">
                            <img className="grid-img" src={`${favorite.listingId}-1.jpg`} alt="photo1" />     
                        </div>
                        <div className="grid-item-content">
                            <h6>{favorite.yearInfo.year} {favorite.makeAndModelInfo.make}</h6>
                            <h3>{favorite.makeAndModelInfo.model}</h3>
                            <div className="row">
                                <div className="col-lg-6">
                                    <span className="mileage">{favorite.mileageInfo.mileage} km</span>
                                </div>
                                <div className="col-lg-6">
                                    <span className="transmission"><GearInfoConverter gearType={favorite.gearInfo.type} numberOfGears={favorite.gearInfo.numGears} /></span>
                                </div>
                                <div className="col-12">
                                    <span className="score">Score: <Badge color="light" pill><ScoreInfoConverter score={favorite.scoreInfo.score} /></Badge></span>
                                </div>
                            </div>
                            <Label>  </Label>
                            <Button onClick={(e: any) => { removeFavorite(e, favorite); props.removeFavorite(e, favorite); }} block>Remove from favorites</Button>
                        </div>
                    </div>
                </Link>
            </div>
        )
        )}
    </div>
   )
};

export default FavoritesView;