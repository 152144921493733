import * as React from 'react';
import SubfilterCountView from '../components/SubfilterCountView';

export interface SubfilterCountProps
{
    count: number;
}

export default class SubfilterCountContainer extends React.Component<SubfilterCountProps, {}>
{
    constructor(props: SubfilterCountProps)
    {
        super(props);
    }

    public render()
    {
        return ( <SubfilterCountView count={this.props.count} /> );
    }
};
