
import * as React from 'react';
import { withRouter, RouteComponentProps} from 'react-router-dom';

import { NotificationContext, Notification } from './NotificationContext';





class NotificationContextProvider extends React.Component<RouteComponentProps, 
{
    notifications: Notification[], 
    addNotification(notification: Notification): any, 
    removeNotification(notification: Notification): any}> {

    private initialState: any;

    constructor(props: any){
        super(props);

        this.addNotification = this.addNotification.bind(this);
        this.removeNotification = this.removeNotification.bind(this);

        this.state = {
            notifications: [],
            addNotification: this.addNotification,
            removeNotification: this.removeNotification
        }

        this.initialState = this.state;
        
    }

    public addNotification(notification: Notification){

        console.log(notification);
        let notifications = this.state.notifications;

        notification.isOpen = true;

        notifications.push(notification);

        setTimeout(() => this.removeNotification(notification), 5000);


        this.setState({notifications: notifications});
    }

    public removeNotification(notification: Notification){
        let notifications = this.state.notifications;
        
        notifications = notifications.filter(n => n !== notification);
        notification.isOpen = false;

        this.setState({notifications: notifications});
    }


    public render()
    {
        return (
            <NotificationContext.Provider value={this.state}>
                {this.props.children}                
            </NotificationContext.Provider>
        );
    }

   
}

export default withRouter(NotificationContextProvider);


  
const NotificationTray = () => {

    const {notifications} = React.useContext(NotificationContext);
  
    // return (
    //      <div >
    //     {notifications.map((notification: Notification) => 
    //     <div>
    //         <Toast isOpen={notification.isOpen} >
    //             <ToastHeader icon="primary" >
    //                 {notification.title}
    //             </ToastHeader>
    //             <ToastBody>
    //                 {notification.body}
    //             </ToastBody>
    //         </Toast>
    //     </div>
  
    //     )}
    //     </div>
    // );


  }
  
  export {NotificationTray}