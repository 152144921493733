import React from 'react';
import { RouteComponentProps } from 'react-router';
import ListingPage from '../components/ListingPage';
import * as _ from 'lodash';
import { UserContext } from '../models/UserContext';
import { Favorite } from '../models/Favorite';
import * as ListingPageStore from '../store/ListingPageStore';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { ErrorMessage, ErrorType } from '../models/Error';
import View404 from './View404';


type ListingPageContainerProps = ListingPageStore.ListingPageState & typeof ListingPageStore.actionCreators & RouteComponentProps<{uid: string}>;



class ListingPageContainer extends React.Component<ListingPageContainerProps> {

    constructor(props: ListingPageContainerProps){
        super(props);

        this.updateSelectedBroker = this.updateSelectedBroker.bind(this);
        this.updateBrokerRequest = this.updateBrokerRequest.bind(this);
        this.sendBrokerRequest = this.sendBrokerRequest.bind(this);
        this.updateCountry = this.updateCountry.bind(this);
        this.onValidate = this.onValidate.bind(this);
    }

    public componentWillReceiveProps(props: ListingPageContainerProps) {
        if(this.props.uid !== props.match.params.uid){
            this.props.setErrorMessage([]);
            this.props.updateListing(props.match.params.uid);
            window.scrollTo(0,0);
            
        }
    }

    public componentDidMount(){
        window.scrollTo(0,0);
        this.props.updateListing(this.props.match.params.uid);
       // this.props.getBrokerProfiles();
        this.props.setErrorMessage([]);
    }

    public updateBrokerRequest(event: React.FormEvent<HTMLInputElement>) {
        const {name, value} = event.currentTarget;
        this.props.updateBrokerRequest(Object.assign({}, this.props.brokerRequest, {[name]: value}));
    }

    public updateCountry(value: string, e: React.ChangeEvent<Element> | undefined)
    {
        this.props.updateBrokerRequest(Object.assign({}, this.props.brokerRequest, {country: value}));
    }

    public sendBrokerRequest()
    {
        this.props.sendBrokerRequest(this.props.brokerRequest);   
    }

    public onValidate(): boolean {
        
        var errorMsgs: ErrorMessage[] = [];

        this.props.setErrorMessage(errorMsgs);


        if(this.props.brokerRequest.name === ""){
            errorMsgs.push(new ErrorMessage(ErrorType.FullName, "Please enter your name."));
        }
        if(this.props.brokerRequest.email === ""){
            errorMsgs.push(new ErrorMessage(ErrorType.Email, "Please enter a valid email address."));
        }
        if(this.props.brokerRequest.country === ""){
            errorMsgs.push(new ErrorMessage(ErrorType.Country, "Please select a country."));
        }

        this.props.setErrorMessage(errorMsgs);
        return errorMsgs.length == 0;
    }

    public updateSelectedBroker(event: React.MouseEvent<HTMLButtonElement>, brokerId: number)
    {
        this.props.updateBrokerRequest(Object.assign({}, this.props.brokerRequest, {brokerId: (this.props.brokerRequest.brokerId == brokerId) ? 0 : +brokerId}));
    }


    public render() {

        var {favorites} = this.context;
        var isFavorited = false;

        if (favorites !== []) {
            isFavorited = favorites.find((f: Favorite) => f.listingId === this.props.listing.listingId) !== undefined;
        }

        return ( this.props.isLoading || this.props.successful ? <ListingPage listing={this.props.listing}
                        selectedBrokerId={this.props.selectedBrokerId}
                        brokerRequest={this.props.brokerRequest}
                         isLoading={this.props.isLoading} 
                         imageSrc={this.props.imageSrc} 
                         brokerProfiles={this.props.brokerProfiles}
                         isLinkCopied={this.props.isLinkCopied}
                         latitude={this.props.latitude}
                         longitude={this.props.longitude}
                         errorMsg={this.props.errorMsg}
                         removeBroken={this.props.removeBroken}
                         onValidate={this.onValidate}
                         share={this.props.share}
                         isFavorited={isFavorited}
                         updateCountry={this.updateCountry}
                         updateSelectedBroker={this.updateSelectedBroker}
                         sendBrokerRequest={this.sendBrokerRequest}
                         updateBrokerRequest={this.updateBrokerRequest}
                         resetLinkState={this.props.resetLinkState} /> : <View404 />
        )
    }

}
ListingPageContainer.contextType = UserContext;

const mapStateToProps = (state: ApplicationState) => state.listingPage;

export default connect(
    mapStateToProps,
    ListingPageStore.actionCreators
)(ListingPageContainer as any);
