import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { CurrentListingResults, Listing } from '../models/Listing';
import {postRequest} from '../utils/http';


export interface RequestRecentlyAdded {type: 'REQUEST_RECENT'; }
export interface RecieveRecentlyAdded {type: 'RECIEVE_RECENT'; listingResults: CurrentListingResults;}

export type KnownAction = RequestRecentlyAdded | RecieveRecentlyAdded;

export interface HomeState {
    listings: Listing[];
    isLoading: boolean;
}

export const actionCreators = {
    getRecentlyAdded: (): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({type: 'REQUEST_RECENT' });

        postRequest<CurrentListingResults>(`auctions/PostRecent`, '')
        .then(results => dispatch({type: 'RECIEVE_RECENT', listingResults: results}));

    }

};
const unloadedState = { listings: [], isLoading: false};
export const reducer: Reducer<HomeState> = (state: HomeState | undefined, incomingAction: Action): HomeState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_RECENT':
            return {
                isLoading: true,
                listings: []
            }
        case 'RECIEVE_RECENT':
            return {
                isLoading: false,
                listings: action.listingResults.listings
            }
        default:
            return state;
    }

};