import React from 'react';
import ListingItem from '../components/ListingItem';
import { Listing } from '../models/Listing';
import { Favorite } from '../models/Favorite';
import { userSession } from '../firebase/Firebase';
import { useReducer, useEffect } from 'react';
import { UserContext } from '../models/UserContext';


interface ListingItemContainerProps {
    listing: Listing;
    userId: string;
    onFavorite(event: React.MouseEvent<HTMLAnchorElement>, listing: Listing): any;
    favorites: Favorite[];
}

class ListingItemContainer extends React.Component<ListingItemContainerProps> {

    static contextType = UserContext;

    constructor(props: ListingItemContainerProps){
        super(props);

    }

    public onFavorite(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
    }


    public render() {
        var {favorites} = this.context;


        var isFavorited = false;

        if(favorites){
            isFavorited = favorites.find((f: Favorite) => f.listingId === this.props.listing.listingId) !== undefined;
        }

        return <ListingItem listing={this.props.listing} userId={this.props.userId} onFavorite={this.onFavorite} isFavorited={isFavorited}  /> 
    }
};


export default ListingItemContainer;
ListingItemContainer.contextType = UserContext;
