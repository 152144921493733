import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Listing, MakeEntry, CurrentListingResults, ModelEntry, LocationEntry, ColorEntry, MakeRegion } from '../models/Listing';
import * as _ from 'lodash';
import SearchParameters from '../models/SearchParameters';
import { Favorite } from '../models/Favorite';
import {Alert} from '../models/Alert';
import {postRequest} from '../utils/http';
import { toast } from 'react-toastify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface ListingContainerState {
    isLoading: boolean;
    isLoadingMoreResults: boolean;
    isFilteredResults: boolean;
    isFilteringResults: boolean;
    filterCount: number;
    listings: Listing[];
    listingCount: number;
    makes: MakeRegion;
    models: ModelEntry[];
    locations: LocationEntry[];
    colors: ColorEntry[];
    minYear: string;
    maxYear: string;
    minOdom: string;
    maxOdom: string;
    minDisplacement: string;
    maxDisplacement: string;
    lotNumber: string;
    searchParams: SearchParameters;
    alertErrorMsg: string;
}
export interface RecieveText {type: 'RECIEVE_TEXT'; name: string; value:string }
export interface RecieveFilterData {type: 'RECIEVE_FILTER_DATA', name: string; value: Promise<any> }
export interface RequestSearchParameters {type: 'REQUEST_SEARCH'; searchParams?: SearchParameters }
export interface UpdateInputSearchParameters {type: 'UPDATE_INPUT_PARAMS'; searchParams?: SearchParameters }
export interface RecieveSearchParameters {type: 'RECIEVE_SEARCH'; listingResults: CurrentListingResults;}
export interface RequestSearchParametersInitial { type: 'REQUEST_SEARCH_INITIAL'; searchParams: SearchParameters; }
export interface RecieveSearchParametersInitial { type: 'RECIEVE_SEARCH_INITIAL'; listingResults: CurrentListingResults; }
export interface RequestScroll { type: 'REQUEST_SCROLL' }
export interface RecieveScroll { type: 'RECIEVE_SCROLL', listingResults: CurrentListingResults; }
export interface ClearFilters { type: 'CLEAR_FILTERS' };
export interface ClearListings {type: 'CLEAR_LISTINGS'};
export interface RequestAddAlert {type: 'REQUEST_ADD_ALERT'; }
export interface RecieveAddAlert {type: 'RECIEVE_ADD_ALERT'; } 
export interface ClearErrorAddAlert {type: 'CLEAR_ERROR_ADD_ALERT';}
export interface ErrorAddAlert {type : 'ALERT_ERROR', message: string; }

export type KnownAction = RequestSearchParameters | RequestSearchParametersInitial | ClearFilters | RecieveFilterData | ClearListings 
                        | RecieveSearchParameters | RecieveSearchParametersInitial | RequestScroll | RecieveScroll | RecieveText
                        | RequestAddAlert | RecieveAddAlert | ErrorAddAlert | ClearErrorAddAlert | UpdateInputSearchParameters;
        
const debounceSearch = _.debounce((dispatch, searchParams) => 
{ 
        dispatch({type: 'REQUEST_SEARCH', searchParams: searchParams}); 
        postRequest<CurrentListingResults>(`auctions/PostUpdateSearch`, searchParams)
        .then(content => { dispatch({type: 'RECIEVE_SEARCH', listingResults: content}) }); 
}, 800);


export const actionCreators = {

    requestFilterData: (url: string, name: string): AppThunkAction<KnownAction> => (dispatch) => {

        postRequest<any>(url, null)
        .then(content =>dispatch({ type: 'RECIEVE_FILTER_DATA', name: name, value: content}));
    },

    debounceSearchParameters : (searchParams?: SearchParameters): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({type: 'UPDATE_INPUT_PARAMS', searchParams: searchParams}); 
        debounceSearch(dispatch, searchParams);

    },

    sendSearchParameters: (searchParams?: SearchParameters): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({type: 'REQUEST_SEARCH', searchParams: searchParams});
        
        postRequest<CurrentListingResults>(`auctions/PostUpdateSearch`, searchParams)
        .then(content => { dispatch({type: 'RECIEVE_SEARCH', listingResults: content}) });

    },

    sendSearchParametersInitial: (searchParams: SearchParameters): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({type: 'REQUEST_SEARCH_INITIAL', searchParams: searchParams});

        postRequest<CurrentListingResults>(`auctions/PostUpdateSearch`, searchParams)
        .then(content =>  dispatch({type: 'RECIEVE_SEARCH_INITIAL', listingResults: content}));

    },

    updateScrolling: (searchParams: SearchParameters) : AppThunkAction<KnownAction> => (dispatch, getState) => {

        const state = getState().listings;
        if(state === undefined || state.isLoadingMoreResults)
            return;
        
        dispatch({type: 'REQUEST_SCROLL'});

        postRequest<CurrentListingResults>(`auctions/PostUpdateSearch`, searchParams)
        .then(content => dispatch({type: 'RECIEVE_SCROLL', listingResults: content}));
    },

    setText: (name: string, value: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'RECIEVE_TEXT', name: name, value: value});
    },
    clearFilters: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'CLEAR_FILTERS'});
    },
    clearListings: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'CLEAR_LISTINGS'});
    },
    onFavorite: (listing: Listing, userId?: string): AppThunkAction<KnownAction> => (dispatch) => {
        
       // const favorite: Favorite = { userId: userId, listingId: listing.listingId, time: listing.auctionLocationInfo.dateInfo.dateTime };

       // postRequest<boolean>(`favorites/PostFavorite`, favorite)
       // .then(content => content);

        var item = window.sessionStorage.getItem("favorites");
        if(item != null)
        {
            var favorites = JSON.parse(item);
            favorites.push(listing.listingId);

            window.sessionStorage.setItem("favorites", JSON.stringify(favorites));
        }
        else
        {
            var favorites:any = [listing.listingId];
            window.sessionStorage.setItem("favorites", JSON.stringify(favorites));

        }

    },
    onAlert: (searchParams: SearchParameters, userId: string, name: string): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({type: 'CLEAR_ERROR_ADD_ALERT'});

        const alert: Alert = { id: -1, userId: userId, make: searchParams.makes, model: searchParams.models, minYear: searchParams.minYear, maxYear: searchParams.maxYear, location: searchParams.locations,
                               minOdom: searchParams.minOdom, maxOdom: searchParams.maxOdom, minScore: searchParams.score,  gearType: searchParams.gearType,  color: searchParams.colors,
                               includeRA: searchParams.isRAchecked, includeR: searchParams.isRchecked, name: name, minDisplacement: searchParams.minDisplacement, maxDisplacement: searchParams.maxDisplacement};

        postRequest<boolean>(`alert/AddAlert`, alert)
        .then(content => { if(content){ toast.dark(`✅ Created alert with name: ${name}`, {toastId: name}); } else toast.error(`❌ Failed to create alert ${name}`)})
        .catch(onRejected => { toast.error(`❌ Failed to create alert ${name}`)});
    },
    onAlertError: (message: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'ALERT_ERROR', message: message});
    }


};

const unloadedState: ListingContainerState = { listings: [], isLoading: false, isLoadingMoreResults: false, isFilteredResults: false, isFilteringResults: false, filterCount: 0, searchParams: { makes: [], models: [], colors: [], locations: [], pageId: 0, minYear: 0, maxYear: 0, minOdom: 0, maxOdom: 0, minDisplacement: 0, maxDisplacement: 0, score: 0, gearType: 0, isRAchecked: false, isRchecked: false, lotNumber: 0 }, listingCount: 0, makes: {}, models: [], locations: [], colors: [], minYear: "", maxYear: "", minOdom: "", maxOdom: "", minDisplacement: "", maxDisplacement: "", lotNumber: "", alertErrorMsg: "" }

export const reducer: Reducer<ListingContainerState> = (state: ListingContainerState | undefined, incomingAction: Action): ListingContainerState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CLEAR_LISTINGS':
            return {
                ...state,
                listings: []
            }
        case 'CLEAR_ERROR_ADD_ALERT':
            return {
                ...state,
                alertErrorMsg: ""
            }
        case 'ALERT_ERROR':
            return {
                ...state,
                alertErrorMsg: action.message
            }
        case 'CLEAR_FILTERS':
            
            return {
                ...state,
                minYear: "",
                maxYear: "",
                minOdom: "",
                maxOdom: "",
                minDisplacement: "",
                maxDisplacement: "",
                lotNumber: "",
                searchParams: unloadedState.searchParams,
                isFilteredResults: false
            };
        case 'RECIEVE_FILTER_DATA':
            return {
                ...state,
                [action.name]: action.value
            }
        case 'RECIEVE_TEXT':
            return {
                ...state,
                [action.name]: action.value
            }
        case 'REQUEST_SEARCH_INITIAL':
            return {
                ...state,
                isLoading: true,
                searchParams: action.searchParams,
                listingCount: 0,
                isFilteredResults: false
        };
        case 'RECIEVE_SEARCH_INITIAL':
            return {
                ...state,
                isLoading: false,
                listings: action.listingResults.listings,
                listingCount: action.listingResults.count,
                searchParams: Object.assign({}, state.searchParams, { pageId: 1 }),
                isFilteredResults: false
            };
        case 'REQUEST_SEARCH':
            if(action.searchParams === undefined)
            {
                return unloadedState;
            }
                
            var count = 0;
            if(action.searchParams.makes.length > 0){ count++; }
            if(action.searchParams.models.length > 0) {count++;}
            if(action.searchParams.colors.length > 0) {count++;}
            if(action.searchParams.locations.length > 0) {count++;}
            if(action.searchParams.minYear !== 0 || action.searchParams.maxYear !== 0){count++;}
            if(action.searchParams.minOdom !== 0 || action.searchParams.maxOdom !== 0){count++;}
            if(action.searchParams.minDisplacement !== 0 || action.searchParams.maxDisplacement !== 0) {count++;}
            if(action.searchParams.score !== 0 || action.searchParams.isRAchecked || action.searchParams.isRchecked) {count++;}
            if(action.searchParams.gearType !== 0) {count++;}
            if(action.searchParams.lotNumber !== 0) {count++;}
            
            return {
                ...state,
                listingCount: 0,
                listings: [],
                searchParams: action.searchParams,
                isFilteringResults: true,
                isFilteredResults: action.searchParams.makes.length !== 0 || action.searchParams.models.length !== 0 || action.searchParams.colors.length !== 0 || action.searchParams.locations.length !== 0 || action.searchParams.minYear !== 0 || action.searchParams.gearType !== 0 ||
                action.searchParams.maxYear !== 0 || action.searchParams.minOdom !== 0 || action.searchParams.maxOdom !== 0 || action.searchParams.score !== 0 || action.searchParams.isRAchecked !== false || action.searchParams.isRchecked !== false ||
                action.searchParams.minDisplacement !== 0 || action.searchParams.maxDisplacement !== 0 || action.searchParams.lotNumber !== 0,
                filterCount: count
            };
        case 'UPDATE_INPUT_PARAMS':
            if(action.searchParams === undefined) {
                return  unloadedState; }

            let tempParams: SearchParameters = state.searchParams;
            tempParams.minYear = action.searchParams.minYear;
            tempParams.maxYear = action.searchParams.maxYear;
            tempParams.minOdom = action.searchParams.minOdom;
            tempParams.maxOdom = action.searchParams.maxOdom;
            tempParams.minDisplacement = action.searchParams.minDisplacement;
            tempParams.maxDisplacement = action.searchParams.maxDisplacement;
            tempParams.lotNumber = action.searchParams.lotNumber;

            return {
                ...state,
                searchParams: tempParams,
            }

            
        case 'RECIEVE_SEARCH':
            //force scroll to top once results come in
            window.scrollTo(0,0);

            return {
                ...state,
                listings: action.listingResults.listings,
                listingCount: action.listingResults.count,
                searchParams: Object.assign({}, state.searchParams, { pageId: 1 }),
                isFilteringResults: false,
                isFilteredResults: state.searchParams.makes.length !== 0 || state.searchParams.models.length !== 0 || state.searchParams.colors.length !==  0 || state.searchParams.minYear !== 0 || state.searchParams.gearType !== 0 ||
                state.searchParams.maxYear !== 0 || state.searchParams.minOdom !== 0 || state.searchParams.maxOdom !== 0 || state.searchParams.score !== 0 || state.searchParams.isRAchecked !== false || state.searchParams.isRchecked !== false ||
                state.searchParams.minDisplacement !== 0 || state.searchParams.maxDisplacement !== 0 || state.searchParams.locations.length !== 0 || state.searchParams.lotNumber !== 0
            
            };
        case 'REQUEST_SCROLL':
            return {
                ...state,
                isLoadingMoreResults: true,
            };
        case 'RECIEVE_SCROLL':

            return {
                ...state,
                listings: state.listings.concat(action.listingResults.listings),
                listingCount: action.listingResults.count,
                isLoadingMoreResults: false,
                searchParams: Object.assign({}, state.searchParams, { pageId: state.searchParams.pageId + 1 })
            };

        case 'REQUEST_ADD_ALERT':
            return state;
        case 'RECIEVE_ADD_ALERT':
            return state;
        default:
            return state;
    }
};