import React from 'react';
import BadgeView from '../components/BadgeView';

export interface BadgeProps {
    isIntExtScore?: boolean;
    score: number;
    outerText: string;
    color?: string;
    
}

export default class BadgeContainer extends React.Component<BadgeProps, {color: string}>
{
    constructor(props: BadgeProps) {
        super(props);

        this.state = {
            color: "primary"
        }
    }

    public componentDidMount()
    {
        if(this.props.isIntExtScore){
            if(this.props.score <= 2)
            {
                this.setState({color: "success"});
            }
            else if(this.props.score <= 4)
            {
                this.setState({color: "warning"});
            }
            else
            {
                this.setState({color: "danger"});
            }
        }


    }

    public render()
    {        
        return this.props.score !== 0 ?
                <BadgeView  outerText={this.props.outerText} 
                            score={this.props.score}
                            color={this.state.color}
                            isIntExtScore={this.props.isIntExtScore} /> : null;

    }


}