import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import ListingView from '../components/ListingView';
import { ModelEntry, Listing } from '../models/Listing';
import  SearchParameters from '../models/SearchParameters';
import {isEmpty} from '../utils/SearchParameterUtils';

import { ApplicationState } from '../store';
import * as ListingStore  from '../store/ListingStore';
import {firebase, userSession} from '../firebase/Firebase';
import { RouteComponentProps } from 'react-router';
import {withRouter} from 'react-router-dom';
import { AuctionsMetaTags } from './MetaTags';
import { titleAuctions } from '../utils/Strings2';


type ListingContainerProps = ListingStore.ListingContainerState &
    typeof ListingStore.actionCreators & RouteComponentProps;

class ListingContainer extends React.Component<ListingContainerProps, {modelValues: ModelEntry[], isViewAllOpen: boolean, alertName: string}> {

    private mScrollReference: any;
    private m_debounceSearch: any;

    constructor(props: any){
        super(props);

        document.title = titleAuctions;
        this.trackScrolling = this.trackScrolling.bind(this);
        this.updateSearchParams = this.updateSearchParams.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.updateModels = this.updateModels.bind(this);
        this.toggleViewAll = this.toggleViewAll.bind(this);
        this.updateModelsView = this.updateModelsView.bind(this);
        this.updateAlertName = this.updateAlertName.bind(this);
        this.onFavorite = this.onFavorite.bind(this);
        this.onAlert = this.onAlert.bind(this);
        this.mScrollReference = _.throttle(this.trackScrolling, 1000);
        this.m_debounceSearch = _.debounce(this.props.sendSearchParameters, 800);

        
        
        this.state = {
            modelValues: this.updateModelsView(),
            isViewAllOpen: false,
            alertName: ""
        }

         var newParams = Object.assign({}, this.props.searchParams, {pageId: 0 });
        this.props.sendSearchParameters(newParams);
    }

    public componentDidMount() {

        window.addEventListener('scroll', this.mScrollReference);

        if(_.isEmpty(this.props.makes)){
            this.props.requestFilterData(`auctions/PostMakes`, 'makes');
        }
        if(this.props.models.length === 0){
            this.props.requestFilterData(`auctions/PostModels`, 'models');
        }
        if(this.props.colors.length === 0){
            this.props.requestFilterData(`auctions/PostColors`, 'colors');
        }
        //if(this.props.locations.length === 0){
        //    this.props.requestFilterData(`auctions/PostLocations`, 'locations');
        //}
    }

    public updateSearchParams(event: React.FormEvent<HTMLInputElement>)
    {
        const {name, value, type, checked} = event.currentTarget;
        var updatedParams;

        if(name === "makes" && type === "checkbox")
        {
            var updatedMakes;
            if(checked)
            {
                updatedMakes = this.props.searchParams.makes;
                updatedMakes.push(+value);
                updatedParams = Object.assign({}, this.props.searchParams, { makes: updatedMakes}, {pageId: 0});
            }
            else
            {
                updatedMakes = this.props.searchParams.makes.filter(m => m !== +value);
                updatedParams = Object.assign({}, this.props.searchParams, { makes: this.props.searchParams.makes.filter(m => m !== +value) }, {pageId: 0 });
            }
        }
        else if(name === "colors" && type === "checkbox")
        {
            if(checked)
            {
                var updatedColors = this.props.searchParams.colors;
                updatedColors.push(+value);
                updatedParams = Object.assign({}, this.props.searchParams, {colors: updatedColors}, {pageId: 0});
            }
            else
            {
                updatedParams = Object.assign({}, this.props.searchParams, {colors: this.props.searchParams.colors.filter(c => c !== +value)}, {pageId: 0});
            }
        }
        else if(name === "locations" && type === "checkbox")
        {
            if(checked)
            {
                var updatedLocations = this.props.searchParams.locations;
                updatedLocations.push(+value);
                updatedParams = Object.assign({}, this.props.searchParams, {locations: updatedLocations}, {pageId: 0});
            }
            else
            {
                updatedParams = Object.assign({}, this.props.searchParams, {locations: this.props.searchParams.locations.filter(c => c !== +value)}, {pageId: 0});
            }
        }
        else if(type === "text")
        {
            var parsed = parseInt(value);
            parsed = (isNaN(parsed)) ? 0 : parsed;
            updatedParams = Object.assign({}, this.props.searchParams, {[name]: parsed}, {pageId: 0});

            this.props.setText(name, value);
            this.props.debounceSearchParameters(updatedParams);
            return;
        }
        else if(type === "checkbox")
        {
            updatedParams = Object.assign({}, this.props.searchParams, {[name]: checked }, {pageId: 0});
        }
        else if(type === "radio")
        {
            updatedParams = Object.assign({}, this.props.searchParams, {[name]: +value}, {pageId: 0});
        }
        
        this.props.sendSearchParameters(updatedParams);
    }
    public clearFilters(event: React.MouseEvent<HTMLAnchorElement>){
        event.preventDefault();
        
        const clearedParams: SearchParameters = { makes: [], models: [], colors: [], locations: [], pageId: 0, minYear: 0, maxYear: 0, minOdom: 0, maxOdom: 0, gearType: 0, score: 0, isRAchecked: false, isRchecked: false, minDisplacement: 0, maxDisplacement: 0, lotNumber: 0 };

        this.props.clearFilters();
        this.setState({modelValues: []});
        this.props.sendSearchParameters(clearedParams);

    }

    public updateAlertName(event: React.FormEvent<HTMLInputElement>){
        this.setState({alertName: event.currentTarget.value});
    }

    public toggleViewAll(event: React.MouseEvent<HTMLAnchorElement>){
        this.setState((prevState) => ({ isViewAllOpen: !prevState.isViewAllOpen }));
        var elemFilterscontainer = document.getElementsByClassName('filters-container')[0];
        var elemFiltersinner = document.getElementsByClassName('filters-inner')[0];
        var icon = document.getElementsByClassName('expandFiltersIcon')[0];
        var elemBody= document.getElementsByTagName('BODY')[0];

        if (elemFilterscontainer == null || elemFiltersinner == null ||  icon == null || elemBody == null)
            return;

        if(this.state.isViewAllOpen)
        {
            elemFilterscontainer.setAttribute("style", "");
            elemFiltersinner.setAttribute("style", "overflow-y: hidden;");
            icon.setAttribute("style", "");
            elemBody.setAttribute("style", "");
            
        }
        else { 
            elemFilterscontainer.setAttribute("style", "height: 100vh; z-index:10; padding-bottom:2em; margin-bottom:2em;");
            elemFiltersinner.setAttribute("style", "margin-bottom:2em;");
            icon.setAttribute("style", "transform: rotate(180deg)");
            elemBody.setAttribute("style", "overflow:hidden");
            
        }
    }


    public componentWillUnmount() {
        window.removeEventListener('scroll', this.mScrollReference);
    }

    public updateModels(value: ModelEntry[], actionMeta: any)
    {
        var newModels = value !== null ? value.map(v => v.id) : [];
        this.setState({ modelValues: value});
        var modelParams = Object.assign({}, this.props.searchParams, {models: newModels}, {pageId: 0});
        this.props.sendSearchParameters(modelParams);
    }

    
    public updateModelsView()
    {
        const modelIds = this.props.searchParams.models;

        let values: ModelEntry[] = [];
        for(let i = 0; i < modelIds.length; i++)
        {   
            let val = this.props.models.find(obj => {
                return obj.id === modelIds[i];
            });

            if(val !== undefined)
                values.push(val);

        }
        return values;
    }

    public onFavorite(event: React.MouseEvent<HTMLAnchorElement>, listing: Listing)
    {
        event.preventDefault();

        var currentUser = firebase.auth().currentUser;

        if(currentUser != null){
            this.props.onFavorite(listing, currentUser.uid);
        }
        else {
            this.props.history.push("/accounts/login");
        }
    }

    public onAlert(event: React.MouseEvent<HTMLButtonElement>)
    {
        event.preventDefault();
        if(this.state.alertName.length > 25)
        {
            this.props.onAlertError("Alert name must be less than 25 characters");
            return;
        }
        if(this.state.alertName.length < 2)
        {
            this.props.onAlertError("Alert name must be at least 2 characters");
            return;
        }

        if(isEmpty(this.props.searchParams))
        {
            this.props.onAlertError("Please select at least one filter criteria");
            return;
        }


        var currentUser = firebase.auth().currentUser;

        if(currentUser != null)
        {
            this.props.onAlert(this.props.searchParams, currentUser.uid, this.state.alertName);
        }
        else {
            this.props.history.push("/accounts/login");
        }
    }

    public render() {
        return ( <div><AuctionsMetaTags /> <ListingView listings={this.props.listings} 
                              isLoading={this.props.isLoading} 
                              isLoadingMoreResults={this.props.isLoadingMoreResults} 
                              isFilteredResults={this.props.isFilteredResults}
                              isFilteringResults={this.props.isFilteringResults}
                              filterCount={this.props.filterCount}
                              makes={this.props.makes}
                              models={this.props.models}
                              colors={this.props.colors}
                              locations={this.props.locations}
                              minYear={this.props.minYear}
                              maxYear={this.props.maxYear}
                              minOdom={this.props.minOdom}
                              maxOdom={this.props.maxOdom}
                              minDisplacement={this.props.minDisplacement}
                              maxDisplacement={this.props.maxDisplacement}
                              lotNumber={this.props.lotNumber}
                              updateMakes={this.updateSearchParams}
                              updateModels={this.updateModels}
                              updateLocations={this.updateSearchParams}
                              modelValues={this.state.modelValues}
                              updateColors={this.updateSearchParams}
                              updateMinYear={this.updateSearchParams}
                              updateMaxYear={this.updateSearchParams}
                              updateMinOdom={this.updateSearchParams}
                              updateMaxOdom={this.updateSearchParams}
                              updateMinDisplacement={this.updateSearchParams}
                              updateMaxDisplacement={this.updateSearchParams}
                              updateScore={this.updateSearchParams}
                              updateRChecked={this.updateSearchParams}
                              updateRAChecked={this.updateSearchParams}
                              updateGearType={this.updateSearchParams}
                              updateLotNumber={this.updateSearchParams}
                              searchParams={this.props.searchParams}
                              clearFilters={this.clearFilters}
                              toggleViewAll={this.toggleViewAll}
                              onFavorite={this.onFavorite}
                              onAlert={this.onAlert}
                              updateAlertName={this.updateAlertName}
                              alertErrorMsg={this.props.alertErrorMsg}
                              alertName={this.state.alertName}
                              isViewAllOpen={this.state.isViewAllOpen}   /></div>)
    }


    private trackScrolling(ev: Event){

        if(this.props.isLoadingMoreResults)
            return;
        //THIS WONT WORK WITH SMALLER WINDOWS, WILL CAAUSE RESULTS NOT TO SHOW
        //var gridContainer = document.querySelector('.grid-container');
        
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 1000) {//figure out better height system
            
            if(this.props.listings.length >= this.props.listingCount)
            {
                return;
            }
            
            this.props.updateScrolling(this.props.searchParams);
        }
    }
    
}

const mapStateToProps = (state: ApplicationState) => state.listings;

export default withRouter(connect(
    mapStateToProps,
    ListingStore.actionCreators
)(ListingContainer));
