import * as React from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

const View404 = () => (
    <div id="notfound">
      <Container>
        <Row>
          <Col>
          <br />
          <br />
          <br />
            <h6>Error 404: page not found</h6>
            <h1>Whoops—looks like you took a wrong turn</h1>
            <h4>We couldn't find that page. The links below should help you get back on the track.</h4>
            <br/>
            <a href="/auctions">View all auctions</a>
            <br/>
            <a href="/">Back to home</a>
          <br />
          <br />
          <br />
          </Col>
        </Row>
      </Container>
    </div>
  )

export default View404;