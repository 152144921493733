import * as React from 'react';
import { ErrorMessage, ErrorType} from '../models/Error';
import { Label, Input, FormGroup } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import { CountryDropdown } from 'react-country-region-selector';

export interface InputGroupProps {
    name: string;
    for?: string;
    type?: InputType;
    children?: React.ReactNode;
    id: string;
    errorType?: ErrorType;
    errorMsg?: string;
    errorMsgs?: ErrorMessage[];
    placeholder?: string;
    onChange?(e: React.ChangeEvent<HTMLInputElement>) : void;
}

const InputGroup = (props: InputGroupProps) => {

    const [showErrorMsg, setShowErrorMsg] = React.useState(false);

    let showErrors: boolean = false;
    let validationClass: string = "";
    if(props.errorMsgs && props.errorMsgs.some((msg) => msg.errorType === props.errorType)){
        showErrors = true;
        validationClass = "validation-error-input";
    }


    return (
    
        <FormGroup>
            <Label for={props.for} >{props.name}</Label>
            <Input type={props.type} name={props.id} id={props.id}  placeholder={props.placeholder} onChange={props.onChange} className={validationClass} />
            {props.children}
            {showErrors && <Label className="validation-error-label">{props.errorMsg}</Label> }
        </FormGroup>

    );
} 

const InnerInputGroup = (props: InputGroupProps) => {

    const [showErrorMsg, setShowErrorMsg] = React.useState(false);

    let showErrors: boolean = false;
    let validationClass: string = "";
    if(props.errorMsgs && props.errorMsgs.some((msg) => msg.errorType === props.errorType)){
        showErrors = true;
        validationClass = "validation-error-input";
    }


    return (
    
        <FormGroup check>
            <Label check for={props.for} >{props.name}
            <Input type={props.type} name={props.id} id={props.id}  placeholder={props.placeholder} onChange={props.onChange} className={validationClass} />
            {props.children}
            {showErrors && <Label className="validation-error-label">{props.errorMsg}</Label> }
            </Label>
        </FormGroup>

    );
}


export interface CountryDropdownGroupProps  extends InputGroupProps {
    value: string;
    onCountryChange(val: string, e: React.ChangeEvent<Element> | undefined): any;
}

const CountryDropdownGroup = (props: CountryDropdownGroupProps) => {

    const [showErrorMsg, setShowErrorMsg] = React.useState(false);

    let showErrors: boolean = false;
    let validationClass: string = "";
    if(props.errorMsgs && props.errorMsgs.some((msg) => msg.errorType === props.errorType)){
        showErrors = true;
        validationClass = "validation-error-input";
    }

    return (
        <FormGroup>
            <Label for={props.for}>{props.name}</Label>
            <CountryDropdown value={props.value} onChange={props.onCountryChange}  classes={validationClass} />
            {props.children}
            {showErrors && <Label className="validation-error-label">{props.errorMsg}</Label> }
        </FormGroup>

    );
}



export { InputGroup, CountryDropdownGroup, InnerInputGroup};