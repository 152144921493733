import * as React from 'react';
import { useState } from 'react';
import { Listing, MakeEntry, ColorEntry, ModelEntry, MakeRegion, LocationEntry } from '../models/Listing';
import ListingItem from '../components/ListingItem';
import ListingItemContainer from '../components/ListingItemContainer';
import SubfilterCountContainer from '../components/SubfilterCountContainer';
import { Row, Col, FormGroup, Label, Input, ListGroup, ListGroupItem, UncontrolledCollapse, Badge, Button, ButtonGroup, InputGroup, InputGroupAddon} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTimes, faChevronDown, faRssSquare, faCircle } from '@fortawesome/free-solid-svg-icons';
import SearchParameters from '../models/SearchParameters';
import ScoreInfoConverter from '../converters/ScoreInfoConverter';
import Select from 'react-select';
import NoResultsHeading from './NoResultsHeading';
import * as _ from 'lodash';
import GenericModalContainer, { ModalType } from './GenericModalContainer';
import LoginContainer from './LoginContainer';
import { UserContext } from '../models/UserContext';
import { AuctionsMetaTags } from './MetaTags';
import { NotificationContext, Notification } from '../models/NotificationContext';

interface ListingViewProps {
    isLoading: boolean;
    isLoadingMoreResults: boolean;
    isFilteredResults: boolean;
    isFilteringResults: boolean;
    isViewAllOpen: boolean;
    filterCount: number;
    listings: Listing[];
    makes: MakeRegion;
    models: ModelEntry[];
    locations: LocationEntry[];
    modelValues: ModelEntry[];
    colors: ColorEntry[];
    searchParams: SearchParameters;
    updateMakes(event: React.FormEvent<HTMLInputElement>): any;
    updateModels(value: any, actionMeta: any): any;
    updateColors(event: React.FormEvent<HTMLInputElement>): any;
    updateLocations(event: React.FormEvent<HTMLInputElement>): any;
    updateMinYear(event: React.FormEvent<HTMLInputElement>): any;
    updateMaxYear(event: React.FormEvent<HTMLInputElement>): any;
    updateMinOdom(event: React.FormEvent<HTMLInputElement>): any;
    updateMaxOdom(event: React.FormEvent<HTMLInputElement>): any;
    updateMinDisplacement(event: React.FormEvent<HTMLInputElement>): any;
    updateMaxDisplacement(event: React.FormEvent<HTMLInputElement>): any;
    updateLotNumber(event: React.FormEvent<HTMLInputElement>): any;
    updateScore(event: React.FormEvent<HTMLInputElement>): any;
    updateRChecked(event: React.FormEvent<HTMLInputElement>): any;
    updateRAChecked(event: React.FormEvent<HTMLInputElement>): any;
    updateGearType(event: React.FormEvent<HTMLInputElement>): any;
    clearFilters(event: React.MouseEvent<HTMLAnchorElement>): any;
    toggleViewAll(event: React.MouseEvent<HTMLAnchorElement>): any;
    onFavorite(event: React.MouseEvent<HTMLAnchorElement>, listing: Listing): any;
    onAlert(event: React.MouseEvent<HTMLButtonElement>): any;
    updateAlertName(event: React.FormEvent<HTMLInputElement>): any;
    minYear: string;
    maxYear: string;
    minOdom: string;
    maxOdom: string;
    minDisplacement: string;
    maxDisplacement: string;
    lotNumber: string;
    alertErrorMsg: string;
    alertName: string;
}

const ListingView = (props: ListingViewProps) => {
    
    const [loginModal, setLoginModal] = React.useState(false);
    const toggle = () => setLoginModal(!loginModal);

    const filterCount = props.filterCount > 0 ? <span>({props.filterCount} active)</span> : null;
    const yearCount = props.searchParams.minYear === 0 ? props.searchParams.maxYear === 0 ? 0 : 1 : props.searchParams.maxYear === 0 ? 1 : 2;
    const odomCount = props.searchParams.minOdom === 0 ? props.searchParams.maxOdom === 0 ? 0 : 1 : props.searchParams.maxOdom === 0 ? 1 : 2;
    const dispCount = props.searchParams.minDisplacement === 0 ? props.searchParams.maxDisplacement === 0 ? 0 : 1 : props.searchParams.maxDisplacement === 0 ? 1 : 2;
    const {user} = React.useContext(UserContext);
   // const {addNotification} = React.useContext(NotificationContext);

    const closeLogin = () => {
        setLoginModal(false);
    }

    
    return (
        props.isLoading ? <div className="loading-spinner"></div> : !props.listings 
        ? (null) 
        : ( 
                <Row className="page-content">
                    <Col lg={4} xl={3} id="filterCol">
                            <div className="filters-container" >
                                <div className="filters-inner">
                                    <div id="filtersheading">
                                        <h2>Filters</h2>
                                        <a id="clearFilters" href="#" onClick={props.clearFilters}>clear all {filterCount}  <FontAwesomeIcon icon={faTimes} /> </a>
                                        <a id="expandFiltersMobile" onClick={props.toggleViewAll} >view all <FontAwesomeIcon className="expandFiltersIcon" icon={faChevronDown} /></a>
                                    </div>
                                    <form id="filterForm">
                                        <ListGroup>
                                            
                                            <ListGroupItem id="makeToggler"><span>Make / Manufacturer <FontAwesomeIcon icon={faAngleDown} /></span><SubfilterCountContainer count={props.searchParams.makes.length} /></ListGroupItem>
                                            <ListGroup>
                                                <UncontrolledCollapse toggler="#makeToggler">
                                                {Object.keys(props.makes).map((key:string,index: number) => (
                                                            <div key={key+index}>
                                                            <span className="region-heading">{key}</span>
                                                            {props.makes[key].map((makeEntry: MakeEntry) =>
                                                            <FormGroup key={makeEntry.id} check>
                                                                <Label check>
                                                                    <Input type="checkbox" name="makes" checked={props.searchParams.makes.indexOf(makeEntry.id) !== -1} value={makeEntry.id} onChange={props.updateMakes}  />{' '}
                                                                    {makeEntry.description} 
                                                                </Label>
                                                            </FormGroup>
                                                            )}
                                                            </div>
                                                        ))}
                                                </UncontrolledCollapse>
                                            </ListGroup>

                                            <ListGroupItem>
                                                <FormGroup>
                                                    <Label>
                                                       Model name <SubfilterCountContainer count={props.searchParams.models.length} />
                                                    </Label>
                                                    
                                                    <Select value={props.modelValues}  options={props.models} getOptionLabel={(option: ModelEntry)  => `${option.model}`} getOptionValue={option => option.id.toString()} placeholder="e.g. Skyline, Impreza" name="models" isMulti={true}  onChange={props.updateModels} />
                                                    
                                                </FormGroup>
                                            </ListGroupItem>

                                            <ListGroupItem id="yearToggler"><span>Year <FontAwesomeIcon icon={faAngleDown} /></span><SubfilterCountContainer count={yearCount} /></ListGroupItem>
                                            <ListGroup>
                                                <UncontrolledCollapse toggler="#yearToggler">
                                                    <ListGroupItem>
                                                        <div className="row">
                                                            <div className="col-6">

                                                                <FormGroup>
                                                                    <Label>
                                                                        Min year
                                                                        </Label>
                                                                    <Input type="text" maxLength={4} name="minYear" placeholder="1960" value={props.minYear} onChange={props.updateMinYear} />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-6">
                                                            <FormGroup>
                                                                <Label>
                                                                    Max year
                                                                    </Label>
                                                                    <Input type="text" maxLength={4} name="maxYear" placeholder="2020" value={props.maxYear} onChange={props.updateMaxYear} />
                                                            </FormGroup>
                                                            </div>
                                                        </div>
                                                    </ListGroupItem>
                                                </UncontrolledCollapse>
                                            </ListGroup>

                                            <ListGroupItem id="odoToggler"><span>Odometer <FontAwesomeIcon icon={faAngleDown} /></span><SubfilterCountContainer count={odomCount} /></ListGroupItem>
                                            <ListGroup>
                                                <UncontrolledCollapse toggler="#odoToggler">
                                                    <ListGroupItem>
                                                        <div className="row">
                                                            <div className="col-6">

                                                                <FormGroup>
                                                                    <Label>
                                                                        Min Odometer
                                                                        </Label>
                                                                    <Input type="text" maxLength={8} name="minOdom" value={props.minOdom} onChange={props.updateMinOdom} placeholder="0" />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-6">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Max Odometer
                                                                    </Label>
                                                                    <Input type="text" maxLength={8} name="maxOdom" value={props.maxOdom} onChange={props.updateMaxOdom} placeholder="100000" />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </ListGroupItem>
                                                </UncontrolledCollapse>
                                            </ListGroup>

                                            <ListGroupItem id="dispToggler"><span>Displacement <FontAwesomeIcon icon={faAngleDown} /></span><SubfilterCountContainer count={dispCount} /></ListGroupItem>
                                            <ListGroup>
                                                <UncontrolledCollapse toggler="#dispToggler">
                                                    <ListGroupItem>
                                                        <div className="row">
                                                            <div className="col-6">

                                                                <FormGroup>
                                                                    <Label>
                                                                        Min Displacement
                                                                        </Label>
                                                                    <Input type="text" maxLength={5} name="minDisplacement" value={props.minDisplacement} onChange={props.updateMinDisplacement} placeholder="0" />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-6">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Max Displacement
                                                                    </Label>
                                                                    <Input type="text" maxLength={5} name="maxDisplacement" value={props.maxDisplacement} onChange={props.updateMaxDisplacement} placeholder="2400" />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </ListGroupItem>
                                                </UncontrolledCollapse>
                                            </ListGroup>

                                            <ListGroupItem id="scoreToggler"><span>Auction Score <FontAwesomeIcon icon={faAngleDown} /></span><SubfilterCountContainer count={+(props.searchParams.score !== 0)} /></ListGroupItem>
                                            <UncontrolledCollapse toggler="#scoreToggler">
                                                <ListGroupItem>
                                                    <FormGroup tag="fieldset">
                                                        <FormGroup check>
                                                            {Array.from({ length: 10},( v, i) => i).slice(4, 10).filter(i => i !== 6 && i !== 8).map((idx : number) =>
                                                                
                                                                <Label key={idx} check>
                                                                    <Input type="radio" value={idx} name="score" checked={props.searchParams.score === idx} onChange={props.updateScore} />{' '}
                                                                    <ScoreInfoConverter score={idx} /> and above
                                                                </Label>
                                                                
                                                                )} {/* S and above */}
                                                                <Label check>
                                                                    <Input type="radio" value={14} name="score" checked={props.searchParams.score === 14} onChange={props.updateScore} />{' '}
                                                                    "<ScoreInfoConverter score={14} />" and above
                                                                </Label>                                           
                                                        </FormGroup>
                                                        <hr />
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="checkbox" checked={props.searchParams.isRchecked} onChange={props.updateRChecked} name="isRchecked" />{' '}
                                                                Include R
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="checkbox" checked={props.searchParams.isRAchecked} onChange={props.updateRAChecked} name="isRAchecked" />{' '}
                                                                Include RA
                                                            </Label>
                                                        </FormGroup>
                                                    </FormGroup>
                                                </ListGroupItem>
                                            </UncontrolledCollapse>
                                                <ListGroupItem id="colorToggler"><span>Color <FontAwesomeIcon icon={faAngleDown} /></span><SubfilterCountContainer count={props.searchParams.colors.length} /></ListGroupItem>
                                            <ListGroup>

                                                <UncontrolledCollapse toggler="#colorToggler">
                                                    <ListGroupItem>
                                                            <FormGroup check>
                                                                {props.colors.map((colorEntry: ColorEntry) => 
                                                                <Label key={colorEntry.argb} check>
                                                                    <Input name="colors" type="checkbox" checked={props.searchParams.colors.indexOf(colorEntry.argb) !== -1 } value={colorEntry.argb} onChange={props.updateColors} />
                                                                        <FontAwesomeIcon icon={faCircle} color={`#${colorEntry.hex.substring(2, 8)}`} /> {colorEntry.name.charAt(0).toUpperCase() + colorEntry.name.slice(1)}
                                                                </Label>
                                                                )}
                                                            </FormGroup>

                                                    </ListGroupItem>
                                                </UncontrolledCollapse>

                                            </ListGroup>

                                                <ListGroupItem id="gearToggler"><span>Transmission <FontAwesomeIcon icon={faAngleDown} /></span><SubfilterCountContainer count={+(props.searchParams.gearType !== 0)} /></ListGroupItem>
                                            <ListGroup>
                                                <UncontrolledCollapse toggler="#gearToggler">
                                                    <ListGroupItem>
                                                        <FormGroup check>
                                                                
                                                                <Label check>
                                                                    <Input type="radio" value={0} name="gearType" checked={props.searchParams.gearType === 0} onChange={props.updateGearType} />{' '}
                                                                        All
                                                                </Label>
                                                                <Label check>
                                                                    <Input type="radio" value={1} name="gearType" checked={props.searchParams.gearType === 1} onChange={props.updateGearType} />{' '}
                                                                        Automatic
                                                                </Label>
                                                                <Label check>
                                                                    <Input type="radio" value={2} name="gearType" checked={props.searchParams.gearType === 2} onChange={props.updateGearType} />{' '}
                                                                        Manual
                                                            </Label>
                                                            <Label check>
                                                                    <Input type="radio" value={3} name="gearType" checked={props.searchParams.gearType === 3} onChange={props.updateGearType} />{' '}
                                                                        Sequential
                                                            </Label>
                                                        </FormGroup>
                                                    </ListGroupItem>
                                                </UncontrolledCollapse>
                                            </ListGroup>

                                            <ListGroupItem>
                                                        <FormGroup>
                                                            <Label>
                                                                Lot Number <SubfilterCountContainer count={+(props.searchParams.lotNumber > 0)} />
                                                            </Label>
                                                            <Input type="text" maxLength={8} name="lotNumber" value={props.lotNumber} onChange={props.updateLotNumber} placeholder="24156" />
                                                        </FormGroup>
                                                        
                                            </ListGroupItem>

                                            {/* <ListGroupItem id="locationToggler"><span>Auction House <FontAwesomeIcon icon={faAngleDown} /></span><SubfilterCountContainer count={props.searchParams.locations.length} /></ListGroupItem>
                                            <ListGroup>
                                                <UncontrolledCollapse toggler="#locationToggler">
                                                    {props.locations.map((locationEntry: LocationEntry) =>
                                                    <FormGroup key={locationEntry.id} check>
                                                        <Label check>
                                                            <Input type="checkbox" name="locations" checked={props.searchParams.locations.indexOf(locationEntry.id) !== -1} value={locationEntry.id} onChange={props.updateLocations}  />{' '}
                                                            {locationEntry.name} 
                                                        </Label>
                                                    </FormGroup>
                                                    )}
                                                </UncontrolledCollapse>
                                            </ListGroup> */}

                                            {/* <ListGroup>
                                                <ListGroupItem><span>Create an alert</span>
                                                    <FormGroup>          
                                                        <InputGroup>
                                                            <Input type="text" maxLength={25} name="name" placeholder="My alert" onChange={props.updateAlertName} className={props.alertErrorMsg ? "validation-error-input" : ""} />
                                                            <InputGroupAddon addonType="append"><Button className="create-alert" 
                                                            onClick={(e) => { 
                                                                if(!user) { 
                                                                    e.preventDefault(); 
                                                                    toggle(); 
                                                                } else { 
                                                                    props.onAlert(e);  
                                                                }}}>Create</Button></InputGroupAddon>
                                                        </InputGroup>
                                                        {props.alertErrorMsg && <Label className={props.alertErrorMsg ? "validation-error-label" : ""}  >{props.alertErrorMsg}</Label>}
                                                        <p className="p-small">Get daily email alerts with new results that match the above filter criteria</p>
                                                    </FormGroup>
                                                    <GenericModalContainer  isOpen={loginModal}
                                                                            toggle={toggle}
                                                                            title="Log in"
                                                                            type={ModalType.None} >
                                                        <LoginContainer onLoginSuccessful={closeLogin} />

                                                    </GenericModalContainer>

                                                </ListGroupItem>
                                            </ListGroup> */}

                                        </ListGroup>

                                    </form>
                                </div>                            
                            </div>
                    </Col>

                    {props.isFilteringResults ?
                    <Col lg={8} xl={9}>
                        <div className="loading-spinner"></div>                                       
                     </Col>
                    :
                    <Col lg={8} xl={9} id="resultsCol"> 
                        {props.listings.length === 0 ?  
                        <NoResultsHeading />
                        
                        :                              
                        <div className="grid-container">
                        {props.listings.map((listing: Listing) =>
                            <div key={listing.uid} className="grid-item">
                                <ListingItemContainer listing={listing} userId="" onFavorite={props.onFavorite} favorites={[]}  />
                            </div>

                        )}
                        </div>
}
                        {props.isLoadingMoreResults ?
                            <div className="loading-spinner isloadingmore"></div>
                            :
                            <div></div>
                        }
                    </Col>

                    }

                </Row>
        )
    );
}

export default ListingView;
