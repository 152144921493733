import * as React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import {NavMenuContainer} from './NavMenu';
import {sanitizePathname, privacyPolicyInfo, validationErrorEmail, validationErrorBugLocation, validationErrorBugDescription} from '../utils/Strings2';
import { useLocation, useHistory } from 'react-router-dom';
import GenericModalContainer, { ModalType } from './GenericModalContainer';
import {BugReport} from '../models/BugReport';
import { postRequest } from '../utils/http';
import {ErrorMessage, ErrorType } from '../models/Error';
import { InputGroup } from './InputGroup';
import NotificationContextProvider from '../models/NotificationContextProvider';




const Layout = (props: { children?: React.ReactNode }) => {

    let location = useLocation();

    
    const [reportABug, setReportABug] = React.useState(false);
    const toggleReportABug = () => setReportABug(!reportABug);

    const [email, setEmail] = React.useState("");
    const [bugLocation, setBugLocation] = React.useState("");
    const [bugDescription, setBugDescription] = React.useState("");
    const [errorMsgs, setErrorMessage] = React.useState<ErrorMessage[]>([]);

    const sendBugReport = () => { 
        const bugReport: BugReport = { email: email, bugLocation: bugLocation, bugDescription: bugDescription};
        postRequest<boolean>(`Email/ReportABug`, bugReport).then(c => c);
    }

    const validateBugReportInput = () : boolean => {

        let errorMsgs: ErrorMessage[] = [];
        setErrorMessage(errorMsgs);
        if(bugLocation === "") { errorMsgs.push(new ErrorMessage(ErrorType.BugLocation, "")); }
        if(bugDescription === "") { errorMsgs.push(new ErrorMessage(ErrorType.BugDescription, "")); }

        setErrorMessage(errorMsgs);
        return errorMsgs.length == 0;
    }



    return (
        <React.Fragment>
            <NotificationContextProvider>
            <NavMenuContainer/>
            <div className="container-fluid" id={sanitizePathname(location.pathname)}>
                {props.children}
            </div>
            </NotificationContextProvider>
            <footer className="container-fluid">
            <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            ©2024 JDM Auctions Inc. 
                        </Col>
                        <Col xs={12} md={6} className="footer-links">
                            {/* <a href="/">Home</a> | <a href="/contact" >Contact Us</a> | <a href="/brokers/signup">Become a Broker</a> | <a href="#" onClick={(e: any) => {e.preventDefault(); toggleReportABug();}}>Report A Bug</a> */}
                            <a href="/">Home</a> | <a href="#" onClick={(e: any) => {e.preventDefault(); toggleReportABug();}}>Report A Bug</a>
                        </Col>
                    </Row> 
            </Container>
            <GenericModalContainer title="Report A Bug" 
                                   type={ModalType.OkCancel}
                                   isOpen={reportABug} 
                                   okButtonTextOverride="Submit"
                                   onSubmit={sendBugReport}
                                   validateModal={validateBugReportInput}
                                   toggle={toggleReportABug}>
                <Col>
                    <Form>
                        <InputGroup for="email"
                                    name="Email" id="email" type="text" placeholder="email@example.com" onChange={(e) => setEmail(e.target.value)} />

                        <InputGroup for="bugLocation"
                                    name="Which page did you see the bug on?" id="bugLocation" type="textarea"  onChange={(e) => setBugLocation(e.target.value)}
                                    errorMsg={validationErrorBugLocation} errorMsgs={errorMsgs} errorType={ErrorType.BugLocation} />

                        <InputGroup for="bugDescription"
                                    name="What were you trying to do on that page?" id="bugDescription" type="textarea"  onChange={(e) => setBugDescription(e.target.value)}
                                    errorMsg={validationErrorBugDescription} errorMsgs={errorMsgs} errorType={ErrorType.BugDescription} />
                            
                    </Form>
                </Col>
            </GenericModalContainer>
            </footer>
        </React.Fragment>

    );
};

export default Layout;
