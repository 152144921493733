import * as React from 'react';
import { Helmet} from 'react-helmet';
import { Listing } from '../models/Listing';
import { BrokerProfile } from '../models/Broker';

const HomeMetaTags = () => {
    return (
        <Helmet>
            <title>JDM Auctions | Find, buy, and import your dream car.</title>
            <meta name="description" content="Find the JDM cars you've always wanted on the world's most comprehensive Japanese auto auction catalog" />
            <meta name="image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
            <meta property="og:url" content="https://www.japanglobalauctions.com" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="JDM Auctions | Find, buy, and import your dream car." />
            <meta property="og:description" content="Find the JDM cars you've always wanted on the world's most comprehensive Japanese auto auction catalog" />
            <meta property="og:image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />

        </Helmet>
    );
}

const AlertsMetaTags = () => {
    return (
        <Helmet>
            <title>JDM Auctions | My Alerts</title>
            <meta name="description" content="Alerts let you get notified of new auction listings based on specific search filter criteria. New listings that match your alert settings will be emailed to you once per day. " />
            <meta name="image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
            <meta property="og:url" content="https://www.japanglobalauctions.com/alerts" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="JDM Auctions | My Alerts" />
            <meta property="og:description" content="Alerts let you get notified of new auction listings based on specific search filter criteria. New listings that match your alert settings will be emailed to you once per day. " />
            <meta property="og:image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />

        </Helmet>
    )
}

export interface AuctionMetaTagsProps {
    listing: Listing;
}

const AuctionMetaTags = (props: AuctionMetaTagsProps ) => {

    const imageUrl = `https://p3.aleado.com/pic?system=auto&date=${props.listing.auctionLocationInfo.dateInfo.dateTime}&auct=${props.listing.auctionNumberInfo.auctionNumber}&bid=${props.listing.auctionLocationInfo.lotNumber}&number=1`;
    const title = `JGA | ${props.listing.yearInfo.year} ${props.listing.makeAndModelInfo.make} ${props.listing.makeAndModelInfo.model}`;
    const url = `https://www.japanglobalauctions.com/auction/${props.listing.uid}`;
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="image" content={imageUrl} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={imageUrl} />

        </Helmet>
    )
}

const AuctionsMetaTags = () => {
    return (
        <Helmet>
            <title>JDM Auctions | Auctions</title>
            <meta name="description" content="Find the JDM cars you've always wanted on the world's most comprehensive Japanese auto auction catalog, updated every day" />
            <meta name="image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
            <meta property="og:url" content="https://www.japanglobalauctions.com/auctions" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="JDM Auctions | Auctions" />
            <meta property="og:description" content="Find the JDM cars you've always wanted on the world's most comprehensive Japanese auto auction catalog, updated every day" />
            <meta property="og:image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />

        </Helmet>
    );
}

const FavoritesMetaTags = () => {
    return (
        <Helmet>
            <title>JDM Auctions | Favorites</title>
            <meta name="description" content="This page shows all listings you have favorited. If a listing you previously favorited isn't shown here, the auction may have ended." />
            <meta name="image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
            <meta property="og:url" content="https://www.japanglobalauctions.com/favorites" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Japan Global Auctions | Favorites" />
            <meta property="og:description" content="This page shows all listings you have favorited. If a listing you previously favorited isn't shown here, the auction may have ended." />
            <meta property="og:image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
        </Helmet>
    );
}

const SignupMetaTags = () => {
    return (
        <Helmet>
            <title>Japan Global Auctions | Signup</title>
            <meta name="description" content="Create your free account and you'll be able to save auctions to your favorites page as well as create email alerts for your custom search criteria. That way you'll be the first to know about rare finds!" />
            <meta name="image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
            <meta property="og:url" content="https://www.japanglobalauctions.com/accounts/signup" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Japan Global Auctions | Signup" />
            <meta property="og:description" content="Create your free account and you'll be able to save auctions to your favorites page as well as create email alerts for your custom search criteria. That way you'll be the first to know about rare finds!" />
            <meta property="og:image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
        </Helmet>
    );
}

const BrokerSignupMetaTags = () => {
    return (
        <Helmet>
            <title>Japan Global Auctions | Become A Broker</title>
            <meta name="description" content="Get placed in front of high quality leads" />
            <meta name="image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
            <meta property="og:url" content="https://www.japanglobalauctions.com/brokers/signup" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Japan Global Auctions | Become A Broker" />
            <meta property="og:description" content="Get placed in front of high quality leads" />
            <meta property="og:image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
        </Helmet>
    );
}

const BrokersMetaTags = () => {
    return (
        <Helmet>
            <title>Japan Global Auctions | All Brokers</title>
            <meta name="description" content="Find, connect and build a relationship with a broker of your choice." />
            <meta name="image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
            <meta property="og:url" content="https://www.japanglobalauctions.com/brokers" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Japan Global Auctions | All Brokers" />
            <meta property="og:description" content="Find, connect and build a relationship with a broker of your choice." />
            <meta property="og:image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
        </Helmet>
    );
}

export interface BrokerMetaTagsProps {
    broker: BrokerProfile;
}

const BrokerMetaTags = (props: BrokerMetaTagsProps) => {

    const title = `Japan Global Auctions | ${props.broker.name}`;
    const url = `https://www.japanglobalauctions.com/broker/${props.broker.uri}`;
    const image = `https://static.japanglobalauctions.com/profiles/${props.broker.uri}/profile.jpg`;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={props.broker.bio} />
            <meta name="image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={props.broker.bio} />
            <meta property="og:image" content={image} />
        </Helmet>
    );
}

const ContactUsMetaTags = () => {
    return (
        <Helmet>
            <title>Japan Global Auctions | Contact Us</title>
            <meta name="description" content="We'd love to hear from you" />
            <meta name="image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
            <meta property="og:url" content="https://www.japanglobalauctions.com/contact" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Japan Global Auctions | Contact Us" />
            <meta property="og:description" content="We'd love to hear from you" />
            <meta property="og:image" content="https://www.japanglobalauctions.com/jga-hero.jpg" />
        </Helmet>
    );
}



export {HomeMetaTags, AlertsMetaTags, AuctionMetaTags, AuctionsMetaTags, FavoritesMetaTags, SignupMetaTags, BrokerSignupMetaTags, BrokersMetaTags, BrokerMetaTags, ContactUsMetaTags};