import * as React from 'react';
import {compose, withProps} from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import {GoogleMapProps} from '../components/GoogleMapContainer';

const GoogleMapView = compose<GoogleMapProps, GoogleMapProps>(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAjGn8Yjw5pCQUiuS_245Xmep3GFRqxDBQ&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `100%` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap)
    ((props) => {
        return ( <GoogleMap defaultZoom={props.zoomLevel}
                            defaultCenter={{ lat: props.latitude, lng: props.longitude }} >

                    {props.children}

                 </GoogleMap> );
    }
  )

  export default GoogleMapView;