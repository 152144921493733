import {UserContext} from './UserContext';
import * as React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import { Favorite } from './Favorite';
import {firebase} from '../firebase/Firebase';
import { Listing } from './Listing';
import {postRequest} from '../utils/http';
import { UserInfo } from './UserInfo';

class UserContextProvider extends React.Component<RouteComponentProps, 
{
    userId: string, 
    getFavorites(): any, 
    getUserInfo(userId: string): any,
    addFavorite(event: React.MouseEvent<HTMLAnchorElement>, listing: Listing): any,
    removeFavorite(event: React.MouseEvent<HTMLAnchorElement>, listing: Listing): any,
    setUser(user: firebase.User): any, 
    favorites: Favorite[], 
    clear(): any,
    user: firebase.User|undefined,
    userInfo: UserInfo}> {

    private initialState: any;

    constructor(props: any){
        super(props);

        this.getFavorites = this.getFavorites.bind(this);
        this.setUser = this.setUser.bind(this);
        this.addFavorite = this.addFavorite.bind(this);
        this.removeFavorite = this.removeFavorite.bind(this);
        this.clear = this.clear.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);

        this.state = {
            userId: "",
            getFavorites: this.getFavorites,
            setUser: this.setUser,
            addFavorite: this.addFavorite,
            removeFavorite: this.removeFavorite,
            getUserInfo: this.getUserInfo,
            clear: this.clear,
            favorites: [],
            user: undefined,
            userInfo: {} as UserInfo
        }

        this.initialState = this.state;
        
    }

    public setUser(user: firebase.User){
        this.setState({user: user});
    }

    public clear() {
        this.setState(this.initialState);
    }

    public getUserInfo(userId: string) {

        //entry point get user info
        if(this.state.user != null)
        {
            console.log(userId);

            var localStorage = window.localStorage;
            var token = localStorage.getItem("token");
            if(!token)
            {
                postRequest<string>(`user/GetUserToken`, userId)
                .then(tk => 
                    {
                        console.log(tk);
                        localStorage.setItem("token", tk);
                    })
            }



            

            
    

            postRequest<UserInfo>(`user/GetUser`, userId)
            .then(userInfo => this.setState({userInfo: userInfo}));
        }
    }

    public getFavorites() {

        if(this.state.user != null)
        {
            const userId = this.state.user.uid;

           // postRequest<Favorite[]>(`favorites/PostFavoriteList`, userId)
          // .then(favorites => this.setState({favorites: favorites}));
        }
    }

    public addFavorite(event: React.MouseEvent<HTMLAnchorElement>, listing: Listing) {

        event.preventDefault();

        if(!this.state.user){
            this.props.history.push("/accounts/login");
            return;
        }

        const userId = this.state.user.uid;

        const favorite: Favorite = { userId: userId, listingId: listing.listingId, time: listing.auctionLocationInfo.dateInfo.dateTime };

        var item = window.sessionStorage.getItem("favorites");
        var favorites:any = [];
        if(item != null)
        {
            favorites = JSON.parse(item);
        }

        if(!favorites.includes(listing.listingId))
        {
            favorites.push(listing.listingId);
        }

        window.sessionStorage.setItem("favorites", JSON.stringify(favorites));

        this.setState({favorites: favorites});

        //postRequest<boolean>(`favorites/PostFavorite`, favorite)
        //.then((success) => { if(success) { this.state.favorites.push(favorite); this.setState({favorites: this.state.favorites })}});
    }

    public removeFavorite(event: React.MouseEvent<HTMLAnchorElement>, listing: Listing){

        event.preventDefault();

        if(!this.state.user)
        {
            this.props.history.push("/accounts/login");
            return;
        }

        const userId = this.state.user.uid;

        const favorite: Favorite = { userId: userId, listingId: listing.listingId, time: listing.auctionLocationInfo.dateInfo.dateTime };

        var item = window.sessionStorage.getItem("favorites");
        var favorites:any = [];
        if(item != null)
        {
            favorites = JSON.parse(item);
        }

        if(favorites.includes(listing.listingId))
        {
            favorites.splice(favorites.indexOf(listing.listingId));
        }

        window.sessionStorage.setItem("favorites", JSON.stringify(favorites));

        this.setState({favorites: favorites});

       // postRequest<boolean>(`favorites/PostFavorite`, favorite)
       // .then((success) => { if(success) { var favorites = this.state.favorites; favorites = favorites.filter(f => f.listingId !== favorite.listingId); this.setState({favorites: favorites}); } });
    }

    public render() {

        return (
        <UserContext.Provider value={this.state}>
            {this.props.children}
        </UserContext.Provider>

        );
    }
}

export default withRouter(UserContextProvider);