import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {postRequest} from '../utils/http';
import { ConnectionRequest, BidRequest } from '../models/ConnectionRequest';
import { Connect } from 'react-redux';
import {UserInfo} from '../models/UserInfo';
import User from '../models/User';


export interface RequestConnectionRequests {type: 'REQUEST_CONN_REQ'; }
export interface RequestConnections {type: 'REQUEST_CONN'};
export interface ReceiveConnections {type: 'RECEIVE_CONN', users: UserInfo[] }
export interface ReceiveConnectionRequests {type: 'RECEIVE_CONN_REQ', connectionRequests: ConnectionRequest[] }
export interface RequestBidRequests {type: 'REQUEST_BID_REQ';}
export interface ReceiveBidRequests {type: 'RECEIVE_BID_REQ', bidRequests: BidRequest[] }
export interface AcceptConnectionRequest {type: 'ACCEPT_CONN_REQ', result: boolean }
export interface RejectConnectionRequest {type: 'REJECT_CONN_REQ', result: boolean }

export type KnownAction = RequestConnectionRequests | ReceiveConnectionRequests | RequestBidRequests | ReceiveBidRequests
                            | AcceptConnectionRequest | RejectConnectionRequest | RequestConnections | ReceiveConnections;

export interface BrokerDashboardState {
    isLoadingConnectionRequests: boolean;
    isLoadingBidRequests: boolean;
    isLoadingConnections: boolean;
    connectionRequests: ConnectionRequest[];
    bidRequests: BidRequest[];
    connections: UserInfo[];
}

export const actionCreators = {

    getConnections: (brokerId: number, userId: string) : AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({type: 'REQUEST_CONN'});
        let userInfo: UserInfo = {brokerId: brokerId, userId: userId} as UserInfo;

        postRequest<UserInfo[]>(`BrokerProfiles/GetConnections`, userInfo)
        .then(results => dispatch({type: 'RECEIVE_CONN', users: results}));



    },

    getConnectionRequests: (brokerId: number, userId: string): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({type: 'REQUEST_CONN_REQ' });

        let userInfo: UserInfo = {brokerId: brokerId, userId: userId} as UserInfo;
        postRequest<ConnectionRequest[]>(`BrokerProfiles/GetPendingConnectionRequests`, userInfo)
        .then(results => dispatch({type: 'RECEIVE_CONN_REQ', connectionRequests: results}));

    },

    getBidRequests: (brokerId: number, userId: string): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({type: 'REQUEST_BID_REQ'});

        let userInfo: UserInfo = {brokerId: brokerId, userId: userId} as UserInfo;
        postRequest<BidRequest[]>(`BrokerProfiles/GetBidRequests`, userInfo)
        .then(results => dispatch({type: 'RECEIVE_BID_REQ', bidRequests: results}));
    },

    acceptConnectionRequest(connectionRequest: ConnectionRequest){
        postRequest<boolean>(`BrokerProfiles/AcceptConnectionRequest`, connectionRequest)
        .then(result => result);
    },
    rejectConnectionRequest(connectionRequest: ConnectionRequest){
        postRequest<boolean>(`BrokerProfiles/RejectConnectionRequest`, connectionRequest)
        .then(result => result);
    }

};
const unloadedState = { isLoadingConnectionRequests: true, isLoadingBidRequests: true, isLoadingConnections: true, connections: [], connectionRequests: [], bidRequests: []};
export const reducer: Reducer<BrokerDashboardState> = (state: BrokerDashboardState | undefined, incomingAction: Action): BrokerDashboardState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CONN_REQ':
            return {
                ...state,
                isLoadingConnectionRequests: true
            }
        case 'RECEIVE_CONN_REQ':
            return {
                ...state,
                connectionRequests: action.connectionRequests,
                isLoadingConnectionRequests: false
            }
        case 'REQUEST_BID_REQ':
            return {
                ...state,
                isLoadingBidRequests: true
            }
        case 'RECEIVE_BID_REQ':
            return {
                ...state,
                bidRequests: action.bidRequests,
                isLoadingBidRequests: false
                
            }
        case 'REQUEST_CONN':
            return {
                ...state,
                isLoadingConnections: true
            }
        case 'RECEIVE_CONN':
            return {
                ...state,
                isLoadingConnections: false,
                connections: action.users
            }
        default:
            return state;
    }

};