const publicIP = require('react-public-ip');

async function sendRequest_<T>(uri: string, method: string, parameters: any): Promise<T> {

    const response = await fetch(uri, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: parameters
    });
    var content = await response.json() as T;
    return content;

}

export async function postRequest<T>(uri: string, parameters: any): Promise<T> {
    return await sendRequest_<T>(uri, 'POST', JSON.stringify(parameters));
}

export async function getRequest<T>(uri: string): Promise<T> {
    return await sendRequest_<T>(uri, 'GET', null);
}

export async function getIpV6(): Promise<string> {
    return await publicIP.v6();
}

export async function getIpV4(): Promise<string> {
    return await publicIP.v4();
}